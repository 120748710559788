import { Container } from '@material-ui/core';
import { CompactForm, RaGrid } from 'ra-compact-ui';
import React from 'react';
import { Edit, TextInput } from 'react-admin';

const EditCategories = props => {
  return (
    <Container maxWidth="md" disableGutters>
      <Edit {...props}>
        <CompactForm layoutComponents={[RaGrid]}>
          <TextInput fullWidth label="Deskripsi" source="description" />
        </CompactForm>
      </Edit>
    </Container>
  );
};

export default EditCategories;
