import { Button, Container } from '@material-ui/core';
import { CompactForm, RaGrid } from 'ra-compact-ui';
import React, { useState } from 'react';
import {
  AutocompleteArrayInput,
  BooleanInput,
  Confirm,
  DateInput,
  DeleteButton,
  Edit,
  FileField,
  FileInput,
  FormDataConsumer,
  RadioButtonGroupInput,
  ReferenceArrayInput,
  ReferenceField,
  ReferenceInput,
  SelectArrayInput,
  TextField,
  TextInput,
  Toolbar,
  useGetIdentity,
  useRefresh,
  useTranslate,
} from 'react-admin';

const Action = props => {
  const { handleSubmitWithRedirect, invalid, pristine, saving, redirect } =
    props;
  const translate = useTranslate();
  const refresh = useRefresh();
  const [open, setOpen] = useState(false);

  const handleDialog = () => setOpen(!open);

  const handleConfirm = async event => {
    event.preventDefault();
    setOpen(false);
    handleSubmitWithRedirect(redirect);
  };
  return (
    <>
      <Toolbar {...props} style={{ justifyContent: 'space-between' }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleDialog}
          disabled={saving || pristine || invalid}
        >
          {translate('ra.action.edit')}
        </Button>
        <DeleteButton />
      </Toolbar>
      <Confirm
        isOpen={open && !saving}
        title="Simpan Perubahan?"
        content="Pastikan disposisi yang dipilih sudah benar"
        onConfirm={handleConfirm}
        onClose={handleDialog}
      />
    </>
  );
};

const EditIncoming = props => {
  const { identity } = useGetIdentity();
  return (
    <Container maxWidth="md" disableGutters>
      <Edit {...props} mutationMode="pessimistic">
        <CompactForm layoutComponents={[RaGrid]} toolbar={<Action />}>
          <BooleanInput label="Penting?" source="urgent" />
          <TextInput fullWidth label="Judul Surat" source="title" />
          <TextInput fullWidth label="Pengirim Surat" source="from" />
          <TextInput fullWidth label="Surat Ditujukan Kepada" source="to" />
          <RaGrid container spacing={1}>
            <RaGrid item xs={12} md={4}>
              <DateInput fullWidth label="Tanggal Surat" source="date" />
            </RaGrid>
            <RaGrid item xs={12} md={4}>
              <DateInput
                fullWidth
                label="Tanggal Terima Surat"
                source="receipt_date"
              />
            </RaGrid>
            <RaGrid item xs={12} md={4}>
              <DateInput
                fullWidth
                label="Tanggal Input Surat"
                source="input_date"
              />
            </RaGrid>
            <RaGrid item xs={12} md={6}>
              <ReferenceField
                link={false}
                source="category"
                reference="enum_mail_categories"
                label="Kategori Surat"
              >
                <TextField source="description" />
              </ReferenceField>
            </RaGrid>
            <RaGrid item xs={12} md={6}>
              <ReferenceField
                link={false}
                source="type"
                reference="enum_mail_types"
                label="Tipe Surat"
              >
                <TextField source="description" />
              </ReferenceField>
            </RaGrid>
            <RaGrid item xs={12} md={6}>
              <TextField label="Kode Surat" source="code" />
            </RaGrid>
            <RaGrid item xs={12} md={6}>
              <TextField label="No. Buku Agenda" source="agenda_code" />
            </RaGrid>
          </RaGrid>
          <TextInput source="note" label="Catatan" multiline fullWidth />
          <FileInput source="attachments" label="Attachments" multiple>
            <FileField source="src" title="title" target="_blank" />
          </FileInput>
          <FormDataConsumer>
            {({ formData }) => (
              <ReferenceInput
                fullWidth
                source="disposition_start.action"
                reference="enum_disposition_actions"
                label="Disposisi"
                filter={{
                  nin__id: ['KOREKSI_TU', 'REVIEW_TU', 'KIRIM', 'TOLAK'],
                }}
                disabled={formData.disposition_allow_changes !== true}
              >
                <RadioButtonGroupInput optionText="description" />
              </ReferenceInput>
            )}
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData }) =>
              formData.disposition_start?.action === 'DITERUSKAN' ? (
                <RaGrid container spacing={1}>
                  <RaGrid item xs={12} md={4}>
                    <ReferenceArrayInput
                      source="disposition_start.recipient_division_ids"
                      reference="divisions"
                      label="Divisi"
                      disabled={formData.disposition_allow_changes === false}
                      filter={
                        formData.disposition_start?.require_all_response
                          ? { eq__disallow_serial: true }
                          : {}
                      }
                    >
                      {formData.disposition_allow_changes === false ? (
                        <SelectArrayInput optionText="name" />
                      ) : (
                        <AutocompleteArrayInput optionText="name" />
                      )}
                    </ReferenceArrayInput>
                  </RaGrid>
                  {identity?.sysrole_id === 'SEKRETARIS' ? (
                    <RaGrid item xs={12} md={6}>
                      <BooleanInput
                        disabled={formData.disposition_allow_changes === false}
                        source="disposition_start.require_all_response"
                        label="Untuk Pertimbangan?"
                        helperText="*Pertimbangan memerlukan respon dari semua pihak untuk
                        bisa lanjut ke tahap berikutnya"
                      />
                    </RaGrid>
                  ) : (
                    <></>
                  )}
                </RaGrid>
              ) : (
                <></>
              )
            }
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData }) => (
              <TextInput
                source="disposition_start.action_note"
                label="Catatan Disposisi"
                multiline
                fullWidth
                disabled={formData.disposition_allow_changes === false}
              />
            )}
          </FormDataConsumer>
        </CompactForm>
      </Edit>
    </Container>
  );
};

export default EditIncoming;
