import { Container } from '@material-ui/core';
import { CompactForm, RaGrid } from 'ra-compact-ui';
import {
  Edit,
  ImageField,
  ImageInput,
  PasswordInput,
  SaveButton,
  TextInput,
  Toolbar,
  useGetIdentity,
} from 'react-admin';

const Avatar = ({ record, source }) => {
  if (typeof record == 'string') {
    record = {
      [source]: record,
    };
  }
  return <ImageField record={record} source={source} />;
};

const Specimen = ({ record, source }) => {
  if (typeof record == 'string') {
    record = {
      [source]: record,
    };
  }
  return <ImageField record={record} source={source} />;
};

const Paraf = ({ record, source }) => {
  if (typeof record == 'string') {
    record = {
      [source]: record,
    };
  }
  return <ImageField record={record} source={source} />;
};

const Action = props => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

const SettingsPage = props => {
  const { identity, loading } = useGetIdentity();

  return (
    !loading && (
      <Container maxWidth="md" disableGutters>
        <Edit
          {...props}
          title="Ubah Profil Saya"
          basePath="/settings"
          resource="users"
          id={identity.id}
          record={identity}
          mutationMode="pessimistic"
        >
          <CompactForm layoutComponents={[RaGrid]} toolbar={<Action />}>
            <TextInput fullWidth source="fullname" label="Nama Karyawan" />
            <TextInput fullWidth source="email" />
            <PasswordInput
              fullWidth
              source="new_pass"
              label="Ganti Password Baru?"
            />
            <RaGrid container spacing={1}>
              <RaGrid item xs={12} md={4}>
                <ImageInput source="avatar" label="Foto" accept="image/*">
                  <Avatar source="src" />
                </ImageInput>
              </RaGrid>
              <RaGrid item xs={12} md={4}>
                <ImageInput
                  source="specimen"
                  label="Tanda Tangan"
                  accept="image/*"
                >
                  <Specimen source="src" />
                </ImageInput>
              </RaGrid>
              <RaGrid item xs={12} md={4}>
                <ImageInput source="paraf" label="Paraf" accept="image/*">
                  <Paraf source="src" />
                </ImageInput>
              </RaGrid>
            </RaGrid>
          </CompactForm>
        </Edit>
      </Container>
    )
  );
};

export default SettingsPage;
