import { Container } from '@material-ui/core';
import React from 'react';
import { Show, SimpleShowLayout, TextField } from 'react-admin';

const ShowTypes = props => {
  return (
    <Container maxWidth="md" disableGutters>
      <Show {...props}>
        <SimpleShowLayout>
          <TextField source="code" label="Kode Jenis Surat" />
          <TextField source="description" label="Deskripsi Jenis Surat" />
        </SimpleShowLayout>
      </Show>
    </Container>
  );
};

export default ShowTypes;
