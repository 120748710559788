import { SvgIcon } from '@material-ui/core';
const ModedarkIcon = props => (
  <SvgIcon {...props}>
    <circle cx="12.9999" cy="13.2449" r="7.83673" />
    <path opacity="0.4" d="M13 1V25" stroke="#7D7D7D" strokeLinecap="round" />
    <path
      opacity="0.4"
      d="M25 13L1 13"
      stroke="#7D7D7D"
      strokeLinecap="round"
    />
    <path
      opacity="0.4"
      d="M21.4854 21.4854L4.51479 4.51479"
      stroke="#7D7D7D"
      strokeLinecap="round"
    />
    <path
      opacity="0.4"
      d="M4.51477 21.4854L21.4853 4.51479"
      stroke="#7D7D7D"
      strokeLinecap="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.898 15.8058C17.1903 16.1258 16.4049 16.304 15.5778 16.304C12.4646 16.304 9.94092 13.7803 9.94092 10.6671C9.94092 9.84003 10.1191 9.05454 10.4391 8.34692C8.48327 9.23139 7.12244 11.1996 7.12244 13.4856C7.12244 16.5988 9.64613 19.1225 12.7593 19.1225C15.0453 19.1225 17.0135 17.7616 17.898 15.8058Z"
      fill="#C4C4C4"
    />
  </SvgIcon>
);
export default ModedarkIcon;
