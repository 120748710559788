import { useMediaQuery } from '@material-ui/core';
import {
  Datagrid,
  EditButton,
  Filter,
  List,
  ShowButton,
  SimpleList,
  TextField,
  TextInput,
} from 'react-admin';

const GridFilters = props => (
  <Filter {...props}>
    <TextInput fullWidth label="Search by name" source="name" alwaysOn />
  </Filter>
);

const ListMasterTemplates = props => {
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

  return (
    <List {...props} filters={<GridFilters />} exporter={false}>
      {isSmall ? (
        <SimpleList primaryText={record => record.name} linkType="edit" />
      ) : (
        <Datagrid>
          <TextField source="name" label="Nama Template" />
          <EditButton />
          <ShowButton />
        </Datagrid>
      )}
    </List>
  );
};

export default ListMasterTemplates;
