import { SvgIcon } from '@material-ui/core';
const JabatanIcon = props => (
  <SvgIcon {...props}>
    <path d="M11.9968 15.1747C7.68382 15.1747 3.99982 15.8547 3.99982 18.5747C3.99982 21.2957 7.66082 21.9997 11.9968 21.9997C16.3098 21.9997 19.9938 21.3207 19.9938 18.5997C19.9938 15.8787 16.3338 15.1747 11.9968 15.1747Z" />
    <path
      opacity="0.4"
      d="M11.9968 12.5838C14.9348 12.5838 17.2888 10.2288 17.2888 7.29176C17.2888 4.35476 14.9348 1.99976 11.9968 1.99976C9.05983 1.99976 6.70483 4.35476 6.70483 7.29176C6.70483 10.2288 9.05983 12.5838 11.9968 12.5838Z"
    />
  </SvgIcon>
);
export default JabatanIcon;
