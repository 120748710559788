import { SvgIcon } from '@material-ui/core';
const SendtoIcon = props => (
  <SvgIcon {...props}>
    <path d="M13.4108 12.8496L18.5468 9.68516C18.6619 9.61466 18.8091 9.62141 18.9168 9.70466C19.4048 10.0804 19.8407 10.4614 20.1316 10.7817C20.1316 10.7817 20.3822 11.0382 20.4906 11.201C20.6657 11.423 20.75 11.7133 20.75 11.9953C20.75 12.3118 20.6577 12.6111 20.4738 12.8503C20.432 12.8931 20.273 13.0813 20.1228 13.2351C19.2465 14.1921 16.9591 15.7732 15.7561 16.2525C15.5809 16.3297 15.1135 16.491 14.871 16.5C14.6373 16.5 14.4116 16.449 14.1947 16.3372C13.928 16.1835 13.7192 15.945 13.602 15.6622C13.5265 15.4657 13.41 14.8762 13.41 14.8589C13.3346 14.4381 13.2781 13.8471 13.2503 13.1571C13.2452 13.0333 13.3074 12.9133 13.4108 12.8496" />
    <path
      opacity="0.4"
      d="M13.7541 11.1451C13.5277 11.2854 13.2427 11.1061 13.2537 10.8369C13.2808 10.2053 13.3277 9.64882 13.3849 9.2348C13.3937 9.2258 13.5102 8.49151 13.6442 8.24325C13.878 7.78198 14.3366 7.49996 14.8297 7.49996H14.8707C15.1887 7.50821 15.865 7.79023 15.865 7.80748C16.2057 7.94924 16.6504 8.18925 17.1289 8.47801C17.3443 8.60852 17.3494 8.92879 17.134 9.06154L13.7541 11.1451Z"
    />
    <path d="M5.41075 12.8496L10.5468 9.68516C10.6619 9.61466 10.8091 9.62141 10.9168 9.70466C11.4048 10.0804 11.8407 10.4614 12.1316 10.7817C12.1316 10.7817 12.3822 11.0382 12.4906 11.201C12.6657 11.423 12.75 11.7133 12.75 11.9953C12.75 12.3118 12.6577 12.6111 12.4738 12.8503C12.432 12.8931 12.273 13.0813 12.1228 13.2351C11.2465 14.1921 8.95912 15.7732 7.75606 16.2525C7.58095 16.3297 7.1135 16.491 6.87098 16.5C6.63726 16.5 6.41159 16.449 6.19472 16.3372C5.92802 16.1835 5.71921 15.945 5.60198 15.6622C5.52651 15.4657 5.41002 14.8762 5.41002 14.8589C5.33455 14.4381 5.27814 13.8471 5.25029 13.1571C5.24517 13.0333 5.30744 12.9133 5.41075 12.8496" />
    <path
      opacity="0.4"
      d="M5.75415 11.1451C5.52775 11.2854 5.24274 11.1061 5.25373 10.8369C5.28084 10.2053 5.32773 9.64882 5.38488 9.2348C5.39367 9.2258 5.51016 8.49151 5.64424 8.24325C5.87797 7.78198 6.33663 7.49996 6.82972 7.49996H6.87075C7.18873 7.50821 7.865 7.79023 7.865 7.80748C8.20569 7.94924 8.65043 8.18925 9.12887 8.47801C9.34428 8.60852 9.3494 8.92879 9.134 9.06154L5.75415 11.1451Z"
    />
  </SvgIcon>
);
export default SendtoIcon;
