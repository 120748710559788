import { useRecordContext } from 'react-admin';

const RecordProvider = props => {
  const { children } = props;
  const record = useRecordContext(props);

  return children(record);
};

export default RecordProvider;
