import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  styled,
  TextField,
  Typography,
} from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import Background from 'assets/login.jpg';
import React, { useState } from 'react';
import { Notification, useLogin, useNotify } from 'react-admin';
import { useHistory, useLocation } from 'react-router-dom';

const BgBox = styled(Box)(({ theme }) => ({
  // backgroundImage: `linear-gradient(${fade(
  //   theme.palette.primary.dark,
  //   0.95
  // )}, ${fade(
  //   theme.palette.secondary.light,
  //   0.25
  // )}), url(https://source.unsplash.com/1600x900/?nature)`,
  backgroundImage: `url(${Background})`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
}));

const LoginPage = ({ theme }) => {
  const history = useHistory();
  const location = useLocation();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const login = useLogin();
  const notify = useNotify();

  const submit = e => {
    e.preventDefault();
    const afterLogout = window.sessionStorage.getItem('logout');
    login({ username, password }, afterLogout ? '/' : null).catch(() =>
      notify('Invalid username or password')
    );
  };

  const goTo = link => event => {
    event.preventDefault();

    history.push(link, {
      internal: location.state?.internal || true,
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <BgBox
        width="100vw"
        height="100vh"
        bgcolor="primary.dark"
        display="flex"
        justifyContent="center"
        alignItems="center"
        alignContent="center"
        p={{
          xs: 2,
          md: 4,
          lg: 6,
        }}
      >
        <Container maxWidth="xl">
          <Grid container>
            <Grid item xs={12} md={4}>
              <Card component="form" onSubmit={submit}>
                <CardHeader
                  title="Surat Digital LPTTN"
                  subheader="Portal Surat Berbasis Elektronik"
                  avatar={
                    <Box
                      component={Avatar}
                      width={`72px !important`}
                      height={`72px !important`}
                      src={'./logo.png'}
                    />
                  }
                  titleTypographyProps={{ variant: 'h4', component: 'h1' }}
                />
                <CardContent>
                  <Typography variant="h6" component="h2" color="textSecondary">
                    Selamat Datang,
                  </Typography>
                  <Typography color="textSecondary" variant="body2">
                    Silahkan mengisi form terlebih dahulu untuk melanjutkan ke
                    halaman dashboard anda.
                  </Typography>
                  <Box my={2}>
                    <Grid container spacing={2} direction="column">
                      <Grid item>
                        <TextField
                          label="Username"
                          name="username"
                          type="text"
                          value={username}
                          variant="outlined"
                          fullWidth
                          onChange={e => setUsername(e.target.value)}
                        />
                      </Grid>
                      <Grid item>
                        <TextField
                          label="Password"
                          name="password"
                          type="password"
                          value={password}
                          variant="outlined"
                          fullWidth
                          onChange={e => setPassword(e.target.value)}
                        />
                        <Box
                          display="flex"
                          justifyContent="flex-end"
                          marginTop={1}
                        >
                          <Button
                            size="small"
                            onClick={() =>
                              notify(
                                'Harap hubungi pihak IT untuk reset password',
                                'info'
                              )
                            }
                          >
                            Lupa Password?
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                  <Button
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    color="secondary"
                  >
                    Masuk
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </BgBox>
      <Notification />
    </ThemeProvider>
  );
};

export default LoginPage;
