import { Box, Drawer } from '@material-ui/core';
import { useCallback } from 'react';
import {
  Datagrid,
  EditButton,
  Filter,
  List,
  ShowButton,
  TextField,
  TextInput,
} from 'react-admin';
import { Route, useHistory } from 'react-router-dom';
import EditTypes from './edit';
import ShowTypes from './show';

const GridFilters = props => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
  </Filter>
);

const ListType = props => {
  const { resource } = props;
  const history = useHistory();

  const handleClose = useCallback(() => {
    history.push(`/${resource}`);
  }, [history, resource]);

  return (
    <>
      <Route path={`/${resource}/:id`}>
        {({ match, ...rest }) => {
          const isEdit = !!(
            match &&
            match.params &&
            match.params.id !== 'create' &&
            match.isExact
          );

          const isShow = !!(
            match &&
            match.params &&
            match.params.id !== 'create' &&
            !match.isExact
          );

          return (
            <>
              <List
                {...props}
                filters={<GridFilters />}
                title={!isShow && !isEdit ? 'Referensi Jenis Surat' : ' '}
              >
                <Datagrid>
                  <TextField source="code" label="Kode Jenis Surat" />
                  <TextField
                    source="description"
                    label="Deskripsi / Keterangan"
                  />
                  <EditButton />
                  <ShowButton />
                </Datagrid>
              </List>
              <Drawer
                open={isEdit || isShow}
                onClose={handleClose}
                anchor="bottom"
                variant="temporary"
                SlideProps={{
                  transitionProps: { mountOnEnter: true, unmountOnExit: true },
                }}
              >
                <Box height="75vh" p={1}>
                  {isEdit && (
                    <EditTypes
                      id={match?.params?.id}
                      onCancel={handleClose}
                      resource={resource}
                      basePath={props.basePath}
                    />
                  )}
                  {isShow && (
                    <ShowTypes
                      id={match?.params?.id}
                      onCancel={handleClose}
                      resource={resource}
                      basePath={props.basePath}
                    />
                  )}
                </Box>
              </Drawer>
            </>
          );
        }}
      </Route>
    </>
  );
};

export default ListType;
