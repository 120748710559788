import { Button, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import arrayMutators from 'final-form-arrays';
import moment from 'moment';
import { CompactForm, RaGrid } from 'ra-compact-ui';
import RichTextInput from 'ra-input-rich-text';
import React, { useEffect, useState } from 'react';
import {
  AutocompleteArrayInput,
  AutocompleteInput,
  BooleanInput,
  Confirm,
  Create,
  DateInput,
  FileField,
  FileInput,
  FormDataConsumer,
  RadioButtonGroupInput,
  ReferenceArrayInput,
  ReferenceInput,
  SelectInput,
  TextInput,
  Toolbar,
  useGetIdentity,
  useGetOne,
  useRefresh,
  useTranslate,
} from 'react-admin';
import { useForm, useFormState } from 'react-final-form';

const useStyles = makeStyles(theme => ({
  containerForm: {
    width: '100%',
    flexDirection: 'column',
  },
}));

const Action = props => {
  const {
    handleSubmitWithRedirect,
    invalid,
    pristine,
    saving,
    handleSubmit,
    redirect,
  } = props;
  console.log(props);
  const translate = useTranslate();
  const refresh = useRefresh();
  const [open, setOpen] = useState(false);

  const handleDialog = () => setOpen(!open);

  const handleConfirm = async event => {
    event.preventDefault();
    setOpen(false);
    handleSubmitWithRedirect(redirect);
  };

  return (
    <>
      <Toolbar {...props}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleDialog}
          disabled={saving || pristine || invalid}
        >
          {translate('ra.action.add')}
        </Button>
      </Toolbar>
      <Confirm
        isOpen={open && !saving}
        title="Simpan Data Baru?"
        content="Pastikan disposisi yang dipilih sudah benar"
        onConfirm={handleConfirm}
        onClose={handleDialog}
      />
    </>
  );
};

const mutators = {
  ...arrayMutators,
  setTemplate: ([name, content], state, { changeValue }) => {
    changeValue(state, name, () => content);
  },
};

const GetTemplate = ({}) => {
  const form = useForm();
  const { values } = useFormState();
  const { data, loading, error } = useGetOne(
    'master_mail_templates',
    values?.template_id,
    {
      enabled: !!values?.template_id,
    }
  );
  useEffect(() => {
    if (!loading && !error && data) {
      form.mutators.setTemplate('content', data.content);
    } else {
      form.mutators.setTemplate('content', null);
    }
  }, [loading, error, data]);
  return null;
};

const CreateIncoming = props => {
  const classes = useStyles();
  const { identity } = useGetIdentity();
  const [autoCode, setAutoCode] = useState(true);
  const [typeDispo, setTypeDispo] = useState('');
  const [template_id, setTemplateId] = useState('');
  const [template, setTemplate] = useState('');

  const onClickRadioBtn = event => {
    setAutoCode(event === 'manual' ? false : true);
  };

  return (
    <Container maxWidth="md" disableGutters>
      <Create {...props}>
        <CompactForm
          mutators={mutators}
          layoutComponents={[RaGrid]}
          toolbar={<Action />}
        >
          {/* <Grid container className={classes.containerForm}>
          <Grid item sm={5}> */}
          {/* <RadioButtonGroupInput
              fullWidth
              label="Urgensi"
              source="urgent"
              choices={[
                { id: true, name: 'Urgent' },
                { id: false, name: 'Tidak' },
              ]}
            /> */}
          <BooleanInput label="Penting?" source="urgent" />
          <TextInput fullWidth label="Judul Surat" source="title" />
          <TextInput fullWidth label="Surat Ditujukan Kepada" source="to" />

          <RaGrid container spacing={1}>
            <RaGrid item xs={12} md={6}>
              <DateInput
                fullWidth
                label="Tanggal Surat"
                source="date"
                defaultValue={moment().format('YYYY-MM-DD')}
              />
            </RaGrid>
            <RaGrid item xs={12} md={6}>
              <DateInput
                fullWidth
                label="Tanggal Input Surat"
                source="input_date"
                defaultValue={moment().format('YYYY-MM-DD')}
              />
            </RaGrid>
            <RaGrid item xs={12} md={6}>
              <ReferenceInput
                fullWidth
                allowEmpty
                source="category"
                reference="enum_mail_categories"
                label="Kategori Surat"
              >
                <AutocompleteInput optionText="description" />
              </ReferenceInput>
            </RaGrid>
            <RaGrid item xs={12} md={6}>
              <ReferenceInput
                fullWidth
                allowEmpty
                source="type"
                reference="enum_mail_types"
                label="Tipe Surat"
              >
                <AutocompleteInput optionText="description" />
              </ReferenceInput>
            </RaGrid>
          </RaGrid>

          <RadioButtonGroupInput
            fullWidth
            // onChange={onClickRadioBtn}
            label="Jenis Kode Surat"
            source="jenis_code"
            choices={[
              { id: 'manual', name: 'Kode Manual' },
              { id: 'auto', name: 'Kode Otomatis' },
            ]}
          />
          <FormDataConsumer>
            {({ formData }) =>
              formData.jenis_code === 'manual' ? (
                <TextInput fullWidth label="Kode Surat Manual" source="code" />
              ) : (
                <></>
              )
            }
          </FormDataConsumer>
          {/* {autoCode === false ? (
              <TextInput fullWidth label="Kode Surat Manual" source="code" />
            ) : (
              <TextInput
                fullWidth
                label="Code Surat Otomatis"
                source="code"
                disabled
              />
            )} */}
          <ReferenceInput
            allowEmpty
            fullWidth
            label="Pakai Template"
            source="template_id"
            reference="master_mail_templates"
          >
            <SelectInput optionText="name" />
          </ReferenceInput>
          <GetTemplate />
          <RichTextInput
            fullWidth
            label="Isi Surat"
            source="content"
            toolbar={[
              [{ font: [] }, { size: ['small', false, 'large', 'huge'] }],
              ['bold', 'italic', 'underline', 'strike'],
              [{ color: [] }, { background: [] }],
              [{ script: 'sub' }, { script: 'super' }],
              [{ header: 1 }, { header: 2 }, 'blockquote', 'code-block'],
              [
                { list: 'ordered' },
                { list: 'bullet' },
                { indent: '-1' },
                { indent: '+1' },
              ],
              [{ direction: 'rtl' }, { align: [] }],
              ['link', 'image', 'video', 'formula'],
              ['clean'],
            ]}
          />
          <TextInput source="note" label="Catatan" multiline fullWidth />
          <FileInput source="attachments" label="Attachments" multiple>
            <FileField source="src" title="title" target="_blank" />
          </FileInput>
          <ReferenceInput
            fullWidth
            source="type_dispositions"
            reference="enum_disposition_actions"
            label="Disposisi"
            filter={{ in__id: ['REVIEW_TU', 'DITERUSKAN'] }}
            // onChange={e => {
            //   setTypeDispo(e);
            // }}
          >
            {/* <AutocompleteInput optionText="description" /> */}
            <RadioButtonGroupInput optionText="description" />
          </ReferenceInput>
          <FormDataConsumer>
            {({ formData }) =>
              formData.type_dispositions === 'DITERUSKAN' ? (
                // <ArrayInput source="disposisi_teruskan" label="Diteruskan Ke">
                //   <SimpleFormIterator>
                //     <ReferenceInput
                //       source="user_id"
                //       reference="users"
                //       label="Nama">
                //       <AutocompleteInput optionText="fullname" />
                //     </ReferenceInput>
                //     <ReferenceInput
                //       source="division_id"
                //       reference="divisions"
                //       label="Divisi">
                //       <AutocompleteInput optionText="name" />
                //     </ReferenceInput>
                //     <ReferenceInput
                //       source="role_id"
                //       reference="roles"
                //       label="Jabatan">
                //       <AutocompleteInput optionText="name" />
                //     </ReferenceInput>
                //   </SimpleFormIterator>
                // </ArrayInput>
                <RaGrid container spacing={1}>
                  {/* <RaGrid item xs={12} md={4}>
                    <ReferenceArrayInput
                      source="disposisi_teruskan.user_ids"
                      reference="users"
                      label="Karyawan"
                      filter={{ active: true }}
                      sort={{ field: 'fullname', order: 'ASC' }}
                    >
                      <AutocompleteArrayInput optionText="fullname" />
                    </ReferenceArrayInput>
                  </RaGrid>
                  <RaGrid item xs={12} md={4}>
                    <ReferenceArrayInput
                      source="disposisi_teruskan.role_ids"
                      reference="roles"
                      label="Jabatan"
                    >
                      <AutocompleteArrayInput optionText="name" />
                    </ReferenceArrayInput>
                  </RaGrid> */}
                  <RaGrid item xs={12} md={4}>
                    <ReferenceArrayInput
                      source="disposisi_teruskan.division_ids"
                      reference="divisions"
                      label="Divisi"
                      filter={
                        formData.require_all_response
                          ? { eq__disallow_serial: true }
                          : {}
                      }
                    >
                      <AutocompleteArrayInput optionText="name" />
                    </ReferenceArrayInput>
                  </RaGrid>
                  {identity?.sysrole_id === 'SEKRETARIS' ? (
                    <RaGrid item xs={12} md={6}>
                      <BooleanInput
                        source="require_all_response"
                        label="Untuk Pertimbangan?"
                        helperText="*Pertimbangan memerlukan respon dari semua pihak untuk
                        bisa lanjut ke tahap berikutnya"
                      />
                    </RaGrid>
                  ) : (
                    <></>
                  )}
                </RaGrid>
              ) : (
                <></>
              )
            }
          </FormDataConsumer>
          <TextInput
            source="action_note"
            label="Catatan Disposisi"
            multiline
            fullWidth
          />
        </CompactForm>
      </Create>
    </Container>
  );
};

export default CreateIncoming;
