import { Box, Card, CardHeader, Grid, Typography } from '@material-ui/core';
import qs from 'qs';
import {
  List,
  SimpleList,
  Title,
  useAuthenticated,
  useAuthState,
} from 'react-admin';

const StatCard = ({ title, resource, filter, baseResource }) => (
  <Card>
    <CardHeader title={title} titleTypographyProps={{ variant: 'h6' }} />
    <List
      actions={false}
      component="div"
      resource={resource}
      basePath="/"
      filter={filter}
      exporter={false}
      syncWithLocation={false}
      perPage={25}
      title=" "
    >
      <SimpleList
        primaryText={record => record.description}
        leftAvatar={record =>
          record.total > 0 ? (
            <Box
              bgcolor="primary.main"
              width="100%"
              height="100%"
              color="white"
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
              alignContent={'center'}
            >
              <Typography color="inherit">{record.total}</Typography>
            </Box>
          ) : (
            record.total
          )
        }
        linkType={({ record, id }) =>
          `/${baseResource}?${qs.stringify({
            filter: JSON.stringify({
              eq__type: id,
              eq__disposisi_to_me: true,
            }),
          })}`
        }
      />
    </List>
  </Card>
);

const Dashboard = () => {
  useAuthenticated();
  const { loading, authenticated } = useAuthState();
  return (
    authenticated && (
      <>
        <Title title="Dasbor" />
        <Grid container spacing={1}>
          <Grid item xs={12} md={4}>
            <StatCard
              title="Surat Masuk - Baru"
              resource="incoming_mails_stat"
              baseResource="incoming_mails"
              filter={{
                eq__type: 'NEW',
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <StatCard
              title="Surat Internal - Baru"
              resource="internal_mails_stat"
              baseResource="internal_mails"
              filter={{
                eq__type: 'NEW',
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <StatCard
              title="Surat Keluar - Baru"
              resource="outgoing_mails_stat"
              baseResource="outgoing_mails"
              filter={{
                eq__type: 'NEW',
              }}
            />
          </Grid>
        </Grid>
      </>
    )
  );
};

export default Dashboard;
