import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  ButtonBase,
  Grid,
  Link,
  Typography,
  useTheme,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { useMemo, useState } from 'react';
import DocViewer, { DocViewerRenderers } from 'react-doc-viewer';
import { Document, Page } from 'react-pdf';
import Viewer from 'react-viewer';

const OldAttachmentView = ({ documents = [] }) => {
  const [expanded, setExpanded] = useState(0);
  const [activeIndex, setActiveIndex] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const images = useMemo(
    () => documents.filter(f => f.mime.includes('image/')),
    [documents]
  );

  return (
    <>
      {(documents || []).map((d, idx) => (
        <Accordion
          key={idx}
          expanded={expanded === idx}
          onChange={() => setExpanded(expanded !== idx ? idx : false)}
          TransitionProps={{ mountOnEnter: true, unmountOnExit: true }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>{`Lampiran #${idx + 1}: ${d.title}`}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container direction="column" spacing={1}>
              <Grid item>
                {d.mime.includes('pdf') ? (
                  <>
                    <Document
                      file={d.src}
                      onLoadSuccess={({ numPages }) => setNumPages(numPages)}
                    >
                      <Page pageNumber={pageNumber} />
                    </Document>
                    <Typography align="center">
                      Page {pageNumber} of {numPages}
                    </Typography>
                  </>
                ) : d.mime.includes('image/') ? (
                  <ButtonBase
                    onClick={() =>
                      setActiveIndex(images.findIndex(f => f.title === d.title))
                    }
                  >
                    <Box clone width="auto" height="50vh">
                      <img src={d.src} alt={d.title} />
                    </Box>
                  </ButtonBase>
                ) : d.mime.includes(
                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                  ) ? null : (
                  <Typography color="error" variant="h4" component="div">
                    Dokumen tidak dapat ditampilkan di web, harap unduh untuk
                    melihat
                  </Typography>
                )}
              </Grid>
              <Grid item>
                <Link href={d.src} target="_blank" download>
                  Klik untuk download lampiran
                </Link>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      ))}
      <Viewer
        zIndex={10000}
        visible={activeIndex !== null}
        activeIndex={activeIndex}
        images={images}
        onClose={() => setActiveIndex(null)}
      />
    </>
  );
};

const AttachmentView = React.memo(({ documents = [] }) => {
  const theme = useTheme();

  return (
    <Box clone height={'calc(100vh)'}>
      <DocViewer
        theme={{
          secondary: theme.palette.primary.main,
        }}
        pluginRenderers={DocViewerRenderers}
        documents={documents.map(d => ({ ...d, uri: d.src }))}
      />
    </Box>
  );
});

export default AttachmentView;
