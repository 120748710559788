import { Box, Drawer, Typography, useMediaQuery } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { useCallback } from 'react';
import {
  Datagrid,
  EditButton,
  Filter,
  List,
  SimpleList,
  TextField,
  TextInput,
} from 'react-admin';
import { Route, useHistory } from 'react-router-dom';
import EditCategories from './edit';

const GridFilters = props => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
  </Filter>
);

const SmallText = withStyles({
  root: {
    fontSize: '14px',
  },
})(Typography);

const ListCategories = props => {
  const { resource } = props;
  const history = useHistory();
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

  const handleClose = useCallback(() => {
    history.push(`/${resource}`);
  }, [history, resource]);

  return (
    <>
      <Route path={`/${resource}/:id`}>
        {({ match, ...rest }) => {
          const isEdit = !!(
            match &&
            match.params &&
            match.params.id !== 'create' &&
            match.isExact
          );

          const isShow = !!(
            match &&
            match.params &&
            match.params.id !== 'create' &&
            !match.isExact
          );

          return (
            <>
              <List
                {...props}
                filters={<GridFilters />}
                title={!isShow && !isEdit ? 'Klasifikasi Surat' : ' '}
              >
                {isSmall ? (
                  <>
                    <SimpleList
                      primaryText={record => record.description}
                    ></SimpleList>
                  </>
                ) : (
                  <Datagrid>
                    <TextField
                      source="description"
                      label="Deskripsi / Keterangan"
                    />
                    <EditButton />
                  </Datagrid>
                )}
              </List>
              <Drawer
                open={isEdit || isShow}
                onClose={handleClose}
                anchor="bottom"
                variant="temporary"
                SlideProps={{
                  transitionProps: { mountOnEnter: true, unmountOnExit: true },
                }}
              >
                <Box height="75vh" p={1}>
                  {isEdit && (
                    <EditCategories
                      id={match?.params?.id}
                      onCancel={handleClose}
                      resource={resource}
                      basePath={props.basePath}
                    />
                  )}
                  {isShow && <></>}
                </Box>
              </Drawer>
            </>
          );
        }}
      </Route>
    </>
  );
};

export default ListCategories;
