import { useMediaQuery } from '@material-ui/core';
import {
  Datagrid,
  EditButton,
  Filter,
  FunctionField,
  List,
  ReferenceField,
  SimpleList,
  TextField,
  TextInput,
} from 'react-admin';

const GridFilters = props => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
  </Filter>
);

const ListDivisions = props => {
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

  return (
    <List {...props} filters={<GridFilters />} exporter={false}>
      {isSmall ? (
        <SimpleList
          primaryText={record => record.name}
          secondaryText={record => (
            <ReferenceField
              record={record}
              source="parent_division_id"
              reference="divisions"
              link={false}
            >
              <FunctionField
                render={subrecord => `Dibawah divisi ${subrecord.name}`}
              />
            </ReferenceField>
          )}
          linkType="edit"
        />
      ) : (
        <Datagrid>
          <TextField source="name" label="Nama Divisi" />
          <ReferenceField
            source="parent_division_id"
            reference="divisions"
            label="Dibawah Divisi"
            link={false}
          >
            <TextField source="name" />
          </ReferenceField>
          <EditButton />
        </Datagrid>
      )}
    </List>
  );
};

export default ListDivisions;
