import { SvgIcon } from '@material-ui/core';
const JenisKlasifikasiIcon = props => (
  <SvgIcon {...props}>
    <path
      opacity="0.4"
      d="M11.9912 18.6216L5.49945 21.8642C5.00921 22.1303 4.39768 21.9526 4.12348 21.4644C4.0434 21.3109 4.00106 21.1403 4 20.9669V13.7089C4 14.4284 4.40573 14.8726 5.47299 15.3701L11.9912 18.6216Z"
      fillOpacity="0.4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.89526 2H15.0695C17.7773 2 19.9735 3.06605 20 5.79337V20.9668C19.9989 21.1374 19.9565 21.3051 19.8765 21.4554C19.7479 21.7007 19.5259 21.8827 19.2615 21.9598C18.997 22.0368 18.7128 22.0023 18.4741 21.8641L11.9912 18.6215L5.47299 15.3701C4.40573 14.8726 4 14.4284 4 13.7088V5.79337C4 3.06605 6.19625 2 8.89526 2ZM8.22492 9.62227H15.7486C16.1822 9.62227 16.5336 9.26828 16.5336 8.83162C16.5336 8.39495 16.1822 8.04096 15.7486 8.04096H8.22492C7.79137 8.04096 7.43991 8.39495 7.43991 8.83162C7.43991 9.26828 7.79137 9.62227 8.22492 9.62227Z"
      fillOpacity="0.4"
    />
  </SvgIcon>
);
export default JenisKlasifikasiIcon;
