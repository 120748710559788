import {
  Box,
  Fade,
  Grid,
  Paper,
  Tab,
  Tabs,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import PrintIcon from '@material-ui/icons/Print';
import { CompactShowLayout, RaBox, RaGrid } from 'ra-compact-ui';
import React, { useState } from 'react';
import {
  Button,
  DateField,
  EditButton,
  ReferenceField,
  Show,
  TextField,
  TopToolbar,
  useGetIdentity,
  useRefresh,
} from 'react-admin';
import { useHistory } from 'react-router';
import CreateDisposition from 'resources/disposition/create';
import EditDisposition from 'resources/disposition/edit';
import AttachmentView from 'shared/AttachmentView';
import DispositionTable from 'shared/DispositionTable';
import DispositionTree from 'shared/DispositionTree';
import { MailChips, MailInfo } from 'shared/MailDetail';
import PrintFilesDialog from 'shared/PrintFilesDialog';
import RecordProvider from 'shared/RecordProvider';

const useStyles = makeStyles(theme => ({
  subtitle: {
    display: 'block',
    fontWeight: 'bold',
  },
  customBox: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === 'dark' ? 700 : 100],
    padding: theme.spacing(1, 2),
    borderRadius: theme.shape.borderRadius,
  },
}));

const ShowActions = ({ basePath, data }) => {
  const { identity } = useGetIdentity();
  const history = useHistory();

  return (
    <TopToolbar>
      <Box marginRight="auto">
        <Button
          label="Kembali ke daftar surat internal"
          onClick={() => history.goBack()}
        >
          <ArrowBackIcon />
        </Button>
      </Box>
      {identity?.role === 'SEKRETARIS' ||
        (identity?.id === data?.created_by && (
          <EditButton basePath={basePath} record={data} />
        ))}
    </TopToolbar>
  );
};

const ShowInternal = props => {
  const { resource, basePath, id } = props;
  const classes = useStyles();
  const refresh = useRefresh();

  const [tab, setTab] = useState('daftar');
  const [open, setOpen] = useState(false);

  const handleTabChange = (e, v) => setTab(v);

  const handleDialog = () => setOpen(!open);

  return (
    <Show component="div" {...props} actions={<ShowActions />}>
      <CompactShowLayout layoutComponents={[RaGrid, RaBox]}>
        <RaBox my={1}>
          <Typography variant="h6">Detail Surat &amp; Disposisi</Typography>
          <RaBox
            p={2}
            border={1}
            borderRadius="borderRadius"
            borderColor="primary.light"
          >
            <RaGrid container spacing={1} justify="space-between">
              <RaGrid item>
                <RaBox marginBottom={2}>
                  <RecordProvider>
                    {record => (
                      <MailChips record={record} resource={resource} />
                    )}
                  </RecordProvider>
                </RaBox>
                <RaBox>
                  <RecordProvider>
                    {record => <MailInfo record={record} resource={resource} />}
                  </RecordProvider>
                </RaBox>
              </RaGrid>
              <RaGrid item>
                <RaGrid container spacing={2}>
                  <RaGrid item>
                    <RaBox marginBottom={2}>
                      <ReferenceField
                        label="Klasifikasi"
                        source="category"
                        link={false}
                        reference="enum_mail_categories"
                      >
                        <TextField source="description" />
                      </ReferenceField>
                    </RaBox>
                    <ReferenceField
                      label="Jenis"
                      source="type"
                      link={false}
                      reference="enum_mail_types"
                    >
                      <TextField source="description" />
                    </ReferenceField>
                  </RaGrid>
                  <RaGrid item>
                    <RaBox marginBottom={2}>
                      <DateField
                        label="Tanggal Input"
                        source="input_date"
                        locales="id-ID"
                        options={{
                          dateStyle: 'full',
                        }}
                      />
                    </RaBox>
                    <DateField
                      label="Tanggal Surat"
                      source="date"
                      locales="id-ID"
                      options={{
                        dateStyle: 'full',
                      }}
                    />
                  </RaGrid>
                  <RaGrid item>
                    <RaBox marginBottom={2}>
                      <TextField source="agenda_code" label="No. Agenda" />
                    </RaBox>
                    <TextField source="note" label="Catatan" />
                  </RaGrid>
                </RaGrid>
              </RaGrid>
            </RaGrid>
          </RaBox>
        </RaBox>
        <RaBox my={2}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
              <RecordProvider>
                {record => (
                  <>
                    <Tabs
                      value={tab}
                      onChange={handleTabChange}
                      variant="fullWidth"
                    >
                      {record?.disposition_send_id && (
                        <Tab value="edit" label="Ubah Disposisi" />
                      )}
                      <Tab value="daftar" label="Daftar Disposisi" />
                      <Tab value="map" label="Peta Disposisi" />
                    </Tabs>
                    <Fade in={tab === 'daftar'} mountOnEnter unmountOnExit>
                      <RaBox component={Paper} my={1}>
                        <DispositionTable
                          mailId={id}
                          type={resource.replace('_mails', '').toUpperCase()}
                          currentDispositionId={record?.disposition_receive_id}
                          basePath={basePath}
                        />
                      </RaBox>
                    </Fade>
                    <Fade in={tab === 'map'} mountOnEnter unmountOnExit>
                      <RaBox component={Paper} my={1}>
                        <DispositionTree
                          mailId={id}
                          type={resource.replace('_mails', '').toUpperCase()}
                          currentDispositionId={record?.disposition_receive_id}
                        />
                      </RaBox>
                    </Fade>
                    {record?.disposition_send_id && (
                      <Fade in={tab === 'edit'} mountOnEnter unmountOnExit>
                        <RaBox>
                          <EditDisposition
                            id={record?.disposition_send_id}
                            basePath={basePath}
                            resource="mail_dispositions"
                            initialValues={{
                              mail_id: id,
                              classification: resource
                                .replace('_mails', '')
                                .toUpperCase(),
                              previous_disposition_id:
                                record?.disposition_receive_id,
                              previous_disposition_action:
                                record?.disposition_receive_action,
                              agenda_code: record?.agenda_code,
                              code: record?.code,
                              input_date: record?.input_date,
                              date: record?.date,
                              created_at: record?.created_at,
                              content: record?.content,
                              attachments: record?.attachments,
                              need_approval: record?.need_approval,
                              approval_id: record?.approval_id,
                              require_all_response:
                                record?.disposition_require_all_response,
                            }}
                          />
                        </RaBox>
                      </Fade>
                    )}
                    {record?.disposition_receive_id &&
                      !record?.disposition_send_id && (
                        <CreateDisposition
                          basePath={basePath}
                          resource="mail_dispositions"
                          initialValues={{
                            mail_id: id,
                            classification: resource
                              .replace('_mails', '')
                              .toUpperCase(),
                            previous_disposition_id:
                              record?.disposition_receive_id,
                            previous_disposition_action:
                              record?.disposition_receive_action,
                            agenda_code: record?.agenda_code,
                            code: record?.code,
                            input_date: record?.input_date,
                            date: record?.date,
                            created_at: record?.created_at,
                            content: record?.content,
                            attachments: record?.attachments,
                            need_approval: record?.need_approval,
                            approval_id: record?.approval_id,
                            require_all_response:
                              record?.disposition_require_all_response,
                          }}
                        />
                      )}
                  </>
                )}
              </RecordProvider>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid
                container
                justify="space-between"
                alignItems="center"
                alignContent="center"
              >
                <Grid item>
                  <Typography variant="h6">Daftar Dokumen</Typography>
                </Grid>
                <Grid item>
                  <Button
                    size="small"
                    variant="contained"
                    onClick={handleDialog}
                  >
                    <PrintIcon />
                  </Button>
                </Grid>
              </Grid>
              <RecordProvider>
                {record => (
                  <AttachmentView documents={record.preview_attachments} />
                )}
              </RecordProvider>
            </Grid>
          </Grid>
        </RaBox>
        <RecordProvider>
          {record => (
            <PrintFilesDialog
              attachments={record?.original_attachments || []}
              open={open}
              onClose={handleDialog}
              type={resource.replace('_mails', '').toUpperCase()}
              mailId={id}
            />
          )}
        </RecordProvider>
      </CompactShowLayout>
    </Show>
  );
};

export default ShowInternal;
