export const resourceMessages = {
  resources: {
    incoming_mails: {
      name: 'Surat Masuk',
      fields: {
        created_at: 'Tanggal Buat',
        input_date: 'Tanggal Input',
        date: 'Tanggal Surat',
        title: 'Judul',
        code: 'No. Surat',
        agenda_code: 'No. Agenda',
      },
    },
    internal_mails: {
      name: 'Surat Internal',
      fields: {
        created_at: 'Tanggal Buat',
        input_date: 'Tanggal Input',
        date: 'Tanggal Surat',
        title: 'Judul',
        code: 'No. Surat',
        agenda_code: 'No. Agenda',
      },
    },
    outgoing_mails: {
      name: 'Surat Keluar',
      fields: {
        created_at: 'Tanggal Buat',
        input_date: 'Tanggal Input',
        date: 'Tanggal Surat',
        title: 'Judul',
        code: 'No. Surat',
        agenda_code: 'No. Agenda',
      },
    },
  },
};
