import {
  Avatar,
  Box,
  Button,
  ButtonBase,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Menu as MuiMenu,
  MenuItem,
  styled as muiStyled,
  Toolbar,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
// import Brightness4Icon from '@material-ui/icons/Brightness4';
// import Brightness7Icon from '@material-ui/icons/Brightness7';
import CloseIcon from '@material-ui/icons/CloseRounded';
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';
import { Skeleton } from '@material-ui/lab';
import Layout, {
  getContent,
  getDrawerSidebar,
  getHeader,
  getSidebarContent,
  getSidebarTrigger,
  Root,
  useSidebarCtx,
} from '@mui-treasury/layout';
import DashboardIcon from 'assets/icons/DashboardIcon';
import LogoutIcon from 'assets/icons/LogoutIcon';
import MasterIcon from 'assets/icons/MasterIcon';
import ModedarkIcon from 'assets/icons/ModedarkIcon';
import NightmodeIcon from 'assets/icons/NIghtmodeIcon';
import SettingIcon from 'assets/icons/SettingIcon';
import { forwardRef, useEffect, useState } from 'react';
import {
  getResources,
  LoadingIndicator,
  MenuItemLink,
  Notification,
  setSidebarVisibility,
  useGetIdentity,
  useGetResourceLabel,
  useLogout,
  useTranslate,
} from 'react-admin';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import styled, { createGlobalStyle } from 'styled-components';
import theme from './theme';

const Header = getHeader(styled);
const DrawerSidebar = getDrawerSidebar(styled);
const SidebarContent = getSidebarContent(styled);
const SidebarTrigger = getSidebarTrigger(styled);
const Content = getContent(styled);

const scheme = Layout();

scheme.configureHeader(builder => {
  builder.registerConfig('xs', {
    position: 'fixed',
    clipped: true,
  });
});

scheme.configureEdgeSidebar(builder => {
  builder
    .create('sidebar', { anchor: 'left' })
    .registerTemporaryConfig('xs', {
      anchor: 'left',
      width: 256, // 'auto' is only valid for temporary variant
    })
    .registerPermanentConfig('md', {
      width: 256, // px, (%, rem, em is compatible)
      collapsedWidth: 64,
      collapsible: true,
      autoExpanded: true,
      persistentBehavior: 'fit',
    });
});

const LpttnHeader = muiStyled(Header)(() => ({
  // [theme.breakpoints.up('lg')]: {
  // },
  backgroundColor: theme.palette.background.paper,
  // backgroundColor: '#FFF',
}));

const LpttnStyle = createGlobalStyle`
  body {
    background-color: ${theme.palette.background.default};
  }
`;

const useStyles = makeStyles(theme => ({
  dockedSidebar: { border: 'none' },
  main: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    marginTop: '0.5em',
    marginBottom: '1em',
    [theme.breakpoints.only('xs')]: {
      marginTop: 0,
    },
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  open: {},
  closed: {},
}));

const ToggleTheme = forwardRef(({}, ref) => {
  const toggle = () => {
    window.localStorage.setItem(
      'prefers-theme',
      theme.palette.type === 'dark' ? 'light' : 'dark'
    );
    window.location.reload();
  };

  return (
    <MenuItem ref={ref} onClick={toggle}>
      <ListItemIcon>
        {theme.palette.type === 'dark' ? <NightmodeIcon /> : <ModedarkIcon />}
      </ListItemIcon>
      <ListItemText primary="Ubah Tema" />
    </MenuItem>
  );
});

const SidebarToggle = ({ control, sidebarId }) => {
  const toggleSidebar = () => {
    control.setOpen(sidebarId, !control.state.sidebar[sidebarId].open);
  };

  return (
    <Box>
      <IconButton onClick={toggleSidebar}>
        {control.state.sidebar[sidebarId].open ? (
          <CloseIcon color="error" />
        ) : (
          <MenuRoundedIcon />
        )}
      </IconButton>
    </Box>
  );
};

const LpttnSidebar = ({ control, sidebarId, ...props }) => {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { expanded } = useSidebarCtx();

  useEffect(() => {
    if (!isMobile) {
      dispatch(setSidebarVisibility(expanded));
    } else {
      // dispatch(setSidebarVisibility(true));
    }
  }, [isMobile, expanded]);

  return (
    <Box
      color={theme.palette.text.primary}
      borderRight={`0.1px solid ${theme.palette.divider}`}
      {...props}
    />
  );
};

const SidebarMenu = props => {
  const resources = useSelector(getResources);
  const getResourceLabel = useGetResourceLabel();
  const translate = useTranslate();
  const {
    hasDashboard,
    dense,
    classes: classesOverride,
    className,
    onMenuClick,
    logout,
    ...rest
  } = props;
  const classes = useStyles(props);

  return (
    <div className={classes.main} {...rest}>
      <>
        {hasDashboard && (
          <MenuItemLink
            to="/"
            primaryText={translate('ra.page.dashboard')}
            leftIcon={<DashboardIcon />}
            exact
            dense={dense}
          />
        )}
        {resources
          .filter(r => r.hasList)
          .sort(
            (a, b) =>
              (a.options.order !== null && a.options.order !== undefined
                ? a.options.order
                : Number.MAX_SAFE_INTEGER) -
              (b.options.order !== null && b.options.order !== undefined
                ? b.options.order
                : Number.MAX_SAFE_INTEGER)
          )
          .map(resource => (
            <MenuItemLink
              key={resource.name}
              to={{
                pathname: `/${resource.name}`,
                state: { _scrollToTop: true },
              }}
              primaryText={getResourceLabel(resource.name, 2)}
              leftIcon={resource.icon ? <resource.icon /> : <MasterIcon />}
              dense={dense}
            />
          ))}
      </>
    </div>
  );
};

const LayoutWatcher = ({ control, sidebarId }) => {
  const dispatch = useDispatch();
  const open = useSelector(state => state.admin.ui.sidebarOpen);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (isMobile) {
      control.setCollapsed(sidebarId, false);
    } else {
      control.setCollapsed(sidebarId, true);
    }
  }, [isMobile]);

  useEffect(() => {
    control.setOpen(sidebarId, open);
  }, [open]);

  // useEffect(() => {
  //   dispatch(setSidebarVisibility(control.state.sidebar[sidebarId].open));
  // }, [control.state]);

  return null;
};

const MainLayout = ({ children, dashboard, logout, title, ...props }) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { identity, loading } = useGetIdentity();
  const [open, setOpen] = useState(null);
  const doLogout = useLogout();

  useEffect(() => {
    if (!loading && !identity) {
      history.push(
        '/login',
        location.state || {
          nextPathname: location.pathname,
          nextSearch: location.search,
        }
      );
    }
  }, [loading, identity, location]);
  const handleOpen = event => setOpen(event.currentTarget);

  const handleClose = () => setOpen(null);
  const goTo = link => event => {
    event.preventDefault();
    history.push(link);
  };

  return (
    <Root scheme={scheme} theme={theme}>
      {control => (
        <>
          <LpttnStyle />
          <LayoutWatcher control={control} sidebarId="sidebar" />
          <LpttnHeader>
            <Toolbar disableGutters>
              <Grid
                container
                spacing={1}
                justifyContent="space-between"
                alignItems="center"
                alignContent="center"
              >
                {isMobile && (
                  <Grid item>
                    <SidebarToggle control={control} sidebarId="sidebar" />
                  </Grid>
                )}
                <Grid item>
                  <Grid
                    container
                    spacing={1}
                    alignItems="center"
                    alignContent="center"
                  >
                    <Grid item>
                      <Box clone width={48} height={48} mx={1}>
                        <img src="https://surat.lpttn.or.id/logo.png" />
                      </Box>
                    </Grid>
                    {!isMobile && (
                      <Grid item>
                        <Typography
                          variant="h5"
                          component="h1"
                          color="textPrimary"
                          id="react-admin-title"
                        />
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid
                    container
                    spacing={1}
                    alignItems="center"
                    alignContent="center"
                  >
                    <Grid item>
                      <LoadingIndicator />
                    </Grid>
                    <Grid item>
                      <Box marginRight={1}>
                        {!isMobile ? (
                          <Button
                            size="small"
                            color="primary"
                            startIcon={
                              <Box
                                clone
                                width={`24px !important`}
                                height={`24px !important`}
                              >
                                <Avatar
                                  src={
                                    !loading
                                      ? `${process.env.REACT_APP_API_URL}/users/fn/avatar/${identity?.id}`
                                      : null
                                  }
                                />
                              </Box>
                            }
                            onClick={handleOpen}
                          >
                            <Box textAlign="start">
                              <Box clone lineHeight={1}>
                                <Typography variant="subtitle2">
                                  {!loading ? (
                                    identity?.fullname
                                  ) : (
                                    <Skeleton width={48} />
                                  )}
                                </Typography>
                              </Box>
                              <Typography variant="body2" color="textSecondary">
                                {!loading ? (
                                  identity?.divisi
                                ) : (
                                  <Skeleton width={48} />
                                )}
                              </Typography>
                            </Box>
                          </Button>
                        ) : (
                          <ButtonBase
                            component={Avatar}
                            src={
                              !loading
                                ? `${process.env.REACT_APP_API_URL}/users/fn/avatar/${identity?.id}`
                                : null
                            }
                            onClick={handleOpen}
                          />
                        )}
                      </Box>
                      <MuiMenu
                        anchorEl={open}
                        getContentAnchorEl={null}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'center',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'center',
                        }}
                        keepMounted
                        open={Boolean(open)}
                        onClose={handleClose}
                      >
                        <ToggleTheme />
                        <MenuItem
                          component="a"
                          href="/settings"
                          onClick={goTo('/settings')}
                        >
                          <ListItemIcon>
                            <SettingIcon />
                          </ListItemIcon>
                          <ListItemText primary="Pengaturan" />
                        </MenuItem>
                        <MenuItem onClick={doLogout}>
                          <ListItemIcon>
                            <LogoutIcon />
                          </ListItemIcon>
                          <ListItemText primary="Keluar" />
                        </MenuItem>
                      </MuiMenu>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Toolbar>
          </LpttnHeader>
          <DrawerSidebar
            sidebarId="sidebar"
            classes={{
              paperAnchorDockedLeft: classes.dockedSidebar,
            }}
          >
            <SidebarContent>
              <LpttnSidebar
                display="flex"
                flexDirection="column"
                justifyContent="stretch"
                alignContent="stretch"
                alignItems="stretch"
                height="100%"
                width="100%"
                paddingRight={1}
              >
                {/* <Menu logout={logout} hasDashboard={!!dashboard} /> */}
                <SidebarMenu logout={logout} hasDashboard={!!dashboard} />
              </LpttnSidebar>
            </SidebarContent>
          </DrawerSidebar>
          <Content>
            <Box
              my={1}
              px={2}
              color={theme.palette.getContrastText(
                theme.palette.background.default
              )}
            >
              {children}
            </Box>
          </Content>
          <Notification />
        </>
      )}
    </Root>
  );
};

export default MainLayout;
