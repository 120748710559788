const { useRecordContext, ReferenceField, TextField } = require('react-admin');

export const MailField = ({ source, ...props }) => {
  const record = useRecordContext(props);

  return (
    <ReferenceField
      source="mail_id"
      reference={`${record.classification.toLowerCase()}_mails`}
    >
      <TextField source={source} />
    </ReferenceField>
  );
};
