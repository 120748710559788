import { Container } from '@material-ui/core';
import { CompactForm, RaGrid } from 'ra-compact-ui';
import {
  AutocompleteInput,
  Create,
  ReferenceInput,
  TextInput,
} from 'react-admin';

const CreateDivision = props => {
  return (
    <Container maxWidth="md" disableGutters>
      <Create {...props}>
        <CompactForm layoutComponents={[RaGrid]}>
          <TextInput fullWidth label="Nama Divisi" source="name" />
          <ReferenceInput
            source="parent_division_id"
            reference="divisions"
            label="Dibawah Divisi"
            // filter={{ isnull__parent_division_id: true }}
            allowEmpty
            fullWidth
          >
            <AutocompleteInput optionText="name" />
          </ReferenceInput>
        </CompactForm>
      </Create>
    </Container>
  );
};

export default CreateDivision;
