import { Container } from '@material-ui/core';
import {
  Create,
  SimpleForm,
  TextInput,
  ReferenceInput,
  AutocompleteInput,
  PasswordInput,
  ImageInput,
  ImageField,
} from 'react-admin';

import { CompactForm, RaGrid } from 'ra-compact-ui';

const CreateUser = props => (
  <Container maxWidth="md" disableGutters>
    <Create {...props}>
      <CompactForm layoutComponents={[RaGrid]}>
        <ImageInput source="avatar" label="Foto" accept="image/*">
          <ImageField source="src" title="Foto" />
        </ImageInput>
        <RaGrid container spacing={1}>
          <RaGrid item xs={12} md={6}>
            <TextInput fullWidth source="username" />
          </RaGrid>
          <RaGrid item xs={12} md={6}>
            <TextInput fullWidth source="email" />
          </RaGrid>
        </RaGrid>

        <TextInput fullWidth source="fullname" label="Nama Karyawan" />
        <ReferenceInput
          source="role_id"
          reference="roles"
          label="Jabatan"
          fullWidth
        >
          <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput
          source="sysrole_id"
          reference="system_roles"
          label="Hak Akses Pengguna"
          fullWidth
        >
          <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <PasswordInput fullWidth source="password" />
        <RaGrid container spacing={1}>
          <RaGrid item xs={12} md={6}>
            <ImageInput source="specimen" label="Tanda Tangan" accept="image/*">
              <ImageField source="src" title="Specimen Tanda Tangan" />
            </ImageInput>
          </RaGrid>
          <RaGrid item xs={12} md={6}>
            <ImageInput source="paraf" label="Paraf" accept="image/*">
              <ImageField source="src" title="Paraf" />
            </ImageInput>
          </RaGrid>
        </RaGrid>
      </CompactForm>
    </Create>
  </Container>
);

export default CreateUser;
