import { Button, Container, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import useAxios from 'axios-hooks';
import moment from 'moment';
import { CompactForm, RaGrid } from 'ra-compact-ui';
import React, { useMemo, useState } from 'react';
import {
  AutocompleteArrayInput,
  AutocompleteInput,
  BooleanInput,
  Confirm,
  Create,
  DateInput,
  FileField,
  FileInput,
  FormDataConsumer,
  RadioButtonGroupInput,
  ReferenceArrayInput,
  ReferenceInput,
  TextField,
  TextInput,
  Toolbar,
  useGetIdentity,
  useRefresh,
  useTranslate,
} from 'react-admin';

const useStyles = makeStyles(theme => ({
  containerForm: {
    width: '100%',
    flexDirection: 'column',
  },
}));
const AgendaCode = props => {
  const [{ data, loading, error }] = useAxios({
    url: `${process.env.REACT_APP_API_URL}/incoming_mails/fn/previewNextAgendaCode`,
    method: 'GET',
  });
  const record = useMemo(
    () => (!loading && !error ? data : {}),
    [data, loading, error]
  );
  return (
    <>
      <Typography variant="subtitle2">No. Buku Agenda Otomatis</Typography>
      <TextField
        label="No. Buku Agenda Otomatis"
        source="next_code"
        {...props}
        record={record}
      />
    </>
  );
};

const Action = props => {
  const { handleSubmitWithRedirect, invalid, pristine, saving, redirect } =
    props;
  const translate = useTranslate();
  const refresh = useRefresh();
  const [open, setOpen] = useState(false);

  const handleDialog = () => setOpen(!open);

  const handleConfirm = async event => {
    event.preventDefault();
    setOpen(false);
    handleSubmitWithRedirect(redirect);
  };

  return (
    <>
      <Toolbar {...props}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleDialog}
          disabled={saving || pristine || invalid}
        >
          {translate('ra.action.add')}
        </Button>
      </Toolbar>
      <Confirm
        isOpen={open && !saving}
        title="Simpan Data Baru?"
        content="Pastikan disposisi yang dipilih sudah benar"
        onConfirm={handleConfirm}
        onClose={handleDialog}
      />
    </>
  );
};

const CreateIncoming = props => {
  const classes = useStyles();
  const { identity } = useGetIdentity();

  const [autoCode, setAutoCode] = useState(true);

  const [typeDispo, setTypeDispo] = useState('');

  const onClickRadioBtn = event => {
    setAutoCode(event === 'manual' ? false : true);
  };

  return (
    <Container maxWidth="md" disableGutters>
      <Create {...props}>
        <CompactForm
          layoutComponents={[RaGrid]}
          toolbar={<Action />}
          redirect="list"
        >
          {/* <Grid container className={classes.containerForm}>
          <Grid item sm={5}> */}
          {/* <RadioButtonGroupInput
              fullWidth
              label="Urgensi"
              source="urgent"
              choices={[
                { id: true, name: 'Urgent' },
                { id: false, name: 'Tidak' },
              ]}
            /> */}
          <BooleanInput label="Penting?" source="urgent" />
          <AgendaCode />
          <TextInput fullWidth label="Judul Surat" source="title" />
          <TextInput fullWidth label="Pengirim Surat" source="from" />
          <TextInput fullWidth label="Surat Ditujukan Kepada" source="to" />
          <RaGrid container spacing={1}>
            <RaGrid item xs={12} md={4}>
              <DateInput fullWidth label="Tanggal Surat" source="date" />
            </RaGrid>
            <RaGrid item xs={12} md={4}>
              <DateInput
                fullWidth
                label="Tanggal Terima Surat"
                source="receipt_date"
                defaultValue={moment().format('YYYY-MM-DD')}
              />
            </RaGrid>
            <RaGrid item xs={12} md={4}>
              <DateInput
                fullWidth
                label="Tanggal Input Surat"
                source="input_date"
                defaultValue={moment().format('YYYY-MM-DD')}
              />
            </RaGrid>
            <RaGrid item xs={12} md={6}>
              <ReferenceInput
                fullWidth
                allowEmpty
                source="category"
                reference="enum_mail_categories"
                label="Kategori Surat"
              >
                <AutocompleteInput optionText="description" />
              </ReferenceInput>
            </RaGrid>
            <RaGrid item xs={12} md={6}>
              <ReferenceInput
                fullWidth
                allowEmpty
                source="type"
                reference="enum_mail_types"
                label="Tipe Surat"
              >
                <AutocompleteInput optionText="description" />
              </ReferenceInput>
            </RaGrid>
          </RaGrid>

          <RadioButtonGroupInput
            fullWidth
            // onChange={onClickRadioBtn}
            label="Jenis Kode Surat"
            source="jenis_code"
            choices={[{ id: 'manual', name: 'Kode Manual' }]}
            defaultValue="manual"
          />
          <FormDataConsumer>
            {({ formData }) =>
              formData.jenis_code === 'manual' ? (
                <TextInput fullWidth label="Kode Surat Manual" source="code" />
              ) : (
                <></>
              )
            }
          </FormDataConsumer>
          {/* {autoCode === false ? (
              <TextInput fullWidth label="Kode Surat Manual" source="code" />
            ) : (
              <TextInput
                fullWidth
                label="Code Surat Otomatis"
                source="code"
                disabled
              />
            )} */}

          <TextInput source="note" label="Catatan" multiline fullWidth />
          <FileInput source="attachments" label="Attachments" multiple>
            <FileField source="src" title="title" target="_blank" />
          </FileInput>
          <ReferenceInput
            fullWidth
            source="type_dispositions"
            reference="enum_disposition_actions"
            label="Disposisi"
            defaultValue="DITERUSKAN"
            filter={{
              nin__id: [
                'PERTIMBANGAN_OK',
                'PERTIMBANGAN_KHUSUS',
                'KOREKSI_TU',
                'REVIEW_TU',
                'KIRIM',
                'TOLAK',
              ],
            }}
            // onChange={e => {
            //   setTypeDispo(e);
            // }}
          >
            {/* <AutocompleteInput optionText="description" /> */}
            <RadioButtonGroupInput optionText="description" />
          </ReferenceInput>
          <FormDataConsumer>
            {({ formData }) =>
              formData.type_dispositions === 'DITERUSKAN' ? (
                // <ArrayInput source="disposisi_teruskan" label="Diteruskan Ke">
                //   <SimpleFormIterator>
                //     <ReferenceInput
                //       source="user_id"
                //       reference="users"
                //       label="Nama">
                //       <AutocompleteInput optionText="fullname" />
                //     </ReferenceInput>
                //     <ReferenceInput
                //       source="division_id"
                //       reference="divisions"
                //       label="Divisi">
                //       <AutocompleteInput optionText="name" />
                //     </ReferenceInput>
                //     <ReferenceInput
                //       source="role_id"
                //       reference="roles"
                //       label="Jabatan">
                //       <AutocompleteInput optionText="name" />
                //     </ReferenceInput>
                //   </SimpleFormIterator>
                // </ArrayInput>
                <RaGrid container spacing={1}>
                  {/* <RaGrid item xs={12} md={4}>
                    <ReferenceArrayInput
                      source="disposisi_teruskan.user_ids"
                      reference="users"
                      label="Karyawan"
                      filter={{ active: true }}
                      sort={{ field: 'fullname', order: 'ASC' }}
                    >
                      <AutocompleteArrayInput optionText="fullname" />
                    </ReferenceArrayInput>
                  </RaGrid>
                  <RaGrid item xs={12} md={4}>
                    <ReferenceArrayInput
                      source="disposisi_teruskan.role_ids"
                      reference="roles"
                      label="Jabatan"
                    >
                      <AutocompleteArrayInput optionText="name" />
                    </ReferenceArrayInput>
                  </RaGrid> */}
                  <RaGrid item xs={12} md={6}>
                    <ReferenceArrayInput
                      source="disposisi_teruskan.division_ids"
                      reference="divisions"
                      label="Divisi"
                      filter={
                        formData.require_all_response
                          ? { eq__disallow_serial: true }
                          : {}
                      }
                    >
                      <AutocompleteArrayInput optionText="name" />
                    </ReferenceArrayInput>
                  </RaGrid>
                  {identity?.sysrole_id === 'SEKRETARIS' ? (
                    <RaGrid item xs={12} md={6}>
                      <BooleanInput
                        source="require_all_response"
                        label="Untuk Pertimbangan?"
                        helperText="*Pertimbangan memerlukan respon dari semua pihak untuk
                        bisa lanjut ke tahap berikutnya"
                      />
                    </RaGrid>
                  ) : (
                    <></>
                  )}
                </RaGrid>
              ) : (
                <></>
              )
            }
          </FormDataConsumer>
          <TextInput
            source="action_note"
            label="Catatan Disposisi"
            multiline
            fullWidth
          />
          {/* </Grid>
        </Grid> */}
        </CompactForm>
      </Create>
    </Container>
  );
};

export default CreateIncoming;
