import { Box, Divider, Typography, useMediaQuery } from '@material-ui/core';
import React from 'react';
import {
  ArrayField,
  ChipField,
  DateField,
  List,
  Pagination,
  SelectField,
  SimpleList,
  SingleFieldList,
  TextField,
  useRecordContext,
} from 'react-admin';

export const classification = [
  { id: 'DITERUSKAN', name: 'Disposisi' },
  { id: 'ARSIP', name: 'Arsip' },
  { id: 'BALAS', name: 'Pembuatan Surat Balasan' },
  { id: 'REVIEW_TU', name: 'Permintaan Review TU' },
  { id: 'KOREKSI_TU', name: 'Hasil Review TU' },
  { id: 'TOLAK', name: 'Disposisi Ditolak' },
  { id: 'PERTIMBANGAN_OK', name: 'Tanpa pertimbangan khusus' },
  { id: 'PERTIMBANGAN_KHUSUS', name: 'Ada pertimbangan khusus' },
];

const ActionField = props => {
  const record = useRecordContext(props);

  return (
    <>
      <div>
        <SelectField
          record={record}
          choices={classification}
          source="action"
          label="Aksi"
        />
      </div>
      {record.require_all_response && (
        <Typography variant="subtitle2" gutterBottom>
          Untuk Pertimbangan
        </Typography>
      )}
      <TextField
        record={record}
        source="action_note"
        label="Catatan"
        color="textSecondary"
      />
    </>
  );
};

const ReceiverField = props => {
  const record = useRecordContext(props);

  return (
    <>
      <ArrayField record={record} source="recipient_divisions" linkType={false}>
        <SingleFieldList>
          <ChipField source="name" size="small" />
        </SingleFieldList>
      </ArrayField>
      <ArrayField record={record} source="recipient_roles" linkType={false}>
        <SingleFieldList>
          <ChipField source="name" size="small" />
        </SingleFieldList>
      </ArrayField>
      <ArrayField record={record} source="recipient_users" linkType={false}>
        <SingleFieldList>
          <ChipField source="name" size="small" />
        </SingleFieldList>
      </ArrayField>
    </>
  );
};

const DispositionTable = ({ mailId, type, basePath }) => {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

  return (
    <List
      title=" "
      component="div"
      exporter={false}
      actions={null}
      bulkActionButtons={false}
      basePath={basePath}
      resource="mail_dispositions"
      syncWithLocation={false}
      sort={{ field: 'created_at', order: 'desc' }}
      perPage={50}
      pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}
      filter={{ eq__mail_id: mailId, eq__classification: type.toUpperCase() }}
    >
      {/* {isMobile ? ( */}
      <SimpleList
        linkType={false}
        primaryText={record => (
          <>
            <DateField
              record={record}
              source="created_at"
              label="Tanggal"
              showTime
              locales="id-ID"
              options={{
                dateStyle: 'full',
                timeStyle: 'short',
                // hour: 'numeric',
              }}
            />
            <Box>
              <TextField record={record} label="Oleh" source="creator" />
            </Box>
          </>
        )}
        secondaryText={record => (
          <>
            <Box my={1}>
              <ActionField record={record} label="Aksi" source="action" />
            </Box>
            <Box py={2}>
              <ReceiverField record={record} label="Ditujukan Kepada" />
            </Box>
            <Box paddingTop={3.5}>
              <Divider />
            </Box>
          </>
        )}
      />
      {/* ) : (
        <Datagrid rowClick={false}>
          <DateField
            source="created_at"
            label="Tanggal"
            showTime
            locales="id-ID"
            options={{
              dateStyle: 'full',
              timeStyle: 'short',
              // hour: 'numeric',
            }}
          />
          <ActionField label="Aksi" source="action" />
          <TextField label="Oleh" source="creator" />
          <ReceiverField label="Ditujukan Kepada" />
        </Datagrid>
      )} */}
    </List>
  );
};

export default DispositionTable;
