// import {
//   Button,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogTitle,
//   List,
//   ListItem,
//   ListItemSecondaryAction,
//   ListItemText,
//   MenuItem,
//   TextField,
//   Typography,
// } from '@material-ui/core';
// import useAxios from 'axios-hooks';
// import { saveAs } from 'file-saver';
// import { useEffect, useState } from 'react';
// import { useNotify } from 'react-admin';

// const PrintFilesDialog = ({ open, onClose, attachments, type, mailId }) => {
//   const notify = useNotify();
//   const [{ loading }, post] = useAxios(
//     {
//       url: `${
//         process.env.REACT_APP_API_URL
//       }/mail/fn/print/${type.toLowerCase()}/${mailId}`,
//       method: 'POST',
//     },
//     { manual: true }
//   );

//   const [printState, setPrintState] = useState(
//     attachments
//       .filter(a => a.mime === 'application/pdf')
//       .map((a, idx) => ({ ...a, style: 'default', order: idx }))
//   );

//   useEffect(() => {
//     if (open) {
//       setPrintState(
//         attachments
//           .filter(a => a.mime === 'application/pdf')
//           .map((a, idx) => ({ ...a, style: 'default', order: idx }))
//       );
//     }
//   }, [open, attachments, type, mailId]);

//   const handleChange = selected => event =>
//     setPrintState(
//       [
//         ...printState.filter(p => p.src !== selected.src),
//         { ...selected, style: event.target.value },
//       ].sort((a, b) => a.order - b.order)
//     );

//   const print = async () => {
//     try {
//       const res = await post({
//         responseType: 'blob',
//         data: printState.reduce(
//           (obj, p) => ({ ...obj, [p.path]: p.style }),
//           {}
//         ),
//       });
//       if (res.data) {
//         saveAs(res.data, `${mailId}.pdf`);
//         notify('Surat dan lampiran berhasil dicetak!', 'success');
//       } else {
//         notify('Gagal cetak!', 'error');
//       }
//     } catch (error) {
//       console.error(error);
//       notify('Gagal cetak!', 'error');
//     }
//   };

//   return (
//     <Dialog
//       open={open}
//       maxWidth="md"
//       fullWidth
//       TransitionProps={{ mountOnEnter: true, unmountOnExit: true }}>
//       <DialogTitle>Cetak Surat dan Lampiran</DialogTitle>
//       <DialogContent>
//         <Typography variant="body2">
//           Harap lakukan pengaturan format cetak terlebih dahulu untuk
//           masing-masing dokumen
//         </Typography>
//         <List>
//           {printState.map((p, idx) => (
//             <ListItem key={idx}>
//               <ListItemText
//                 primary={p.title}
//                 primaryTypographyProps={{
//                   component: 'a',
//                   href: p.src,
//                   target: '_blank',
//                 }}
//               />
//               <ListItemSecondaryAction>
//                 <TextField select onChange={handleChange(p)} value={p.style}>
//                   <MenuItem value={'default'}>Tanpa format sistem</MenuItem>
//                   <MenuItem value={'header-footer'}>
//                     Dengan kop surat dan tanda tangan digital
//                   </MenuItem>
//                   <MenuItem value={'paraf'}>
//                     Dengan paraf dan tanda tangan digital
//                   </MenuItem>
//                 </TextField>
//               </ListItemSecondaryAction>
//             </ListItem>
//           ))}
//         </List>
//       </DialogContent>
//       <DialogActions>
//         <Button onClick={onClose} disabled={loading}>
//           Batal
//         </Button>
//         <Button
//           variant="contained"
//           color="primary"
//           disabled={loading || printState.length === 0}
//           onClick={print}>
//           Proses &amp; Cetak
//         </Button>
//       </DialogActions>
//     </Dialog>
//   );
// };

// export default PrintFilesDialog;

import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';
import useAxios from 'axios-hooks';
import { saveAs } from 'file-saver';
import { PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import { useEffect, useRef, useState } from 'react';
import { useNotify } from 'react-admin';
import Draggable from 'react-draggable';

const CustomizePdfSign = ({ data, signers = [], onChange, onClose }) => {
  const ref = useRef(null);
  const iframe = useRef(null);
  const [document, setDocument] = useState(null);
  const [positions, setPositions] = useState(
    signers.reduce(
      (obj, s) => ({
        ...obj,
        [s.id]: {
          x: data?.settings ? data?.settings[s.id].x : 0,
          y: data?.settings ? data?.settings[s.id].y : 100,
        },
      }),
      {}
    )
  );

  useEffect(() => {
    setPositions(
      data
        ? signers.reduce(
            (obj, s) => ({
              ...obj,
              [s.id]: {
                x: data?.settings ? data?.settings[s.id].x : 0,
                y: data?.settings ? data?.settings[s.id].y : 100,
              },
            }),
            {}
          )
        : {}
    );
  }, [signers, data]);

  useEffect(() => {
    const loadDocument = async url => {
      const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer());

      const signs = {};
      for (const s of signers) {
        let type = '';
        const imageBytes = await fetch(
          `${process.env.REACT_APP_API_URL}/users/fn/specimen/${s.id}`
        ).then(res => {
          type = res.headers.get('Content-Type');
          return res.arrayBuffer();
        });

        signs[s.id] = { imageBytes, type, ...s };
      }

      setDocument({ pdf: existingPdfBytes, signs });
    };

    if (data && data.src) {
      loadDocument(data.src);
    }
  }, [data, signers]);

  useEffect(() => {
    // console.log(positions);

    const extractPdf = async () => {
      if (document) {
        const newDoc = await PDFDocument.load(document.pdf);

        const helveticaFont = await newDoc.embedFont(StandardFonts.Helvetica);

        if (newDoc.getPageCount() > 1) {
          for (let index = 0; index < newDoc.getPageCount(); index++) {
            newDoc.removePage(index);
          }
        }

        const page = newDoc.getPage(0);
        const { width, height } = page.getSize();
        // console.log(width, height);

        page.drawRectangle({
          x: (2.5 / 100) * width,
          y: (2.5 / 100) * height,
          width: width * 0.95,
          height: height * 0.95,
          borderWidth: 1,
          borderColor: rgb(1, 0.5, 0.5),
          // opacity: 0.25,
        });

        page.drawText('PREVIEW', {
          x: width / 2 - helveticaFont.widthOfTextAtSize('PREVIEW', 128) / 2,
          y: height / 2,
          size: 128,
          font: helveticaFont,
          color: rgb(0.5, 0.5, 0.5),
          opacity: 0.125,
        });

        const keys = Object.keys(positions);

        for (const k of keys) {
          if (positions[k]) {
            const specImage = document.signs[k].type.includes('png')
              ? await newDoc.embedPng(document.signs[k].imageBytes)
              : await newDoc.embedJpg(document.signs[k].imageBytes);

            const specDims = specImage.scaleToFit(100, 100);

            const finalX = (2.5 / 100) * width + (positions[k].x / 100) * width;
            const finalY =
              (2.5 / 100) * height +
              height * 0.95 -
              height * 0.95 * (positions[k].y / 100);

            // page.drawRectangle({
            //   x:
            //     finalX +
            //       helveticaFont.widthOfTextAtSize(
            //         document.signs[k].fullname,
            //         12
            //       ) >
            //     width * 0.9
            //       ? finalX -
            //         helveticaFont.widthOfTextAtSize(
            //           document.signs[k].fullname,
            //           12
            //         ) +
            //         (5 / 100) * width
            //       : finalX,
            //   y: finalY,
            //   width: helveticaFont.widthOfTextAtSize(
            //     document.signs[k].fullname,
            //     12
            //   ),
            //   height: 116,
            //   color: rgb(1, 0, 0),
            // });

            // console.log(
            //   'CALC',
            //   positions[k].x / 100,
            //   positions[k].y / 100,
            //   positions[k].realx,
            //   positions[k].realy,
            //   finalX,
            //   finalY,
            //   width * 0.9,
            //   height * 0.25,
            //   finalX +
            //     helveticaFont.widthOfTextAtSize(
            //       document.signs[k].fullname,
            //       12
            //     ) >
            //     width * 0.9
            // );

            page.drawImage(specImage, {
              x:
                (finalX +
                  helveticaFont.widthOfTextAtSize(
                    document.signs[k].fullname,
                    12
                  ) >
                width * 0.95
                  ? finalX -
                    helveticaFont.widthOfTextAtSize(
                      document.signs[k].fullname,
                      12
                    ) +
                    (15 / 100) * width
                  : finalX) +
                helveticaFont.widthOfTextAtSize(
                  document.signs[k].fullname,
                  12
                ) /
                  4,
              y: finalY + 15,
              width: specDims.width,
              height: specDims.height,
            });

            page.drawText(document.signs[k].fullname, {
              x:
                finalX +
                  helveticaFont.widthOfTextAtSize(
                    document.signs[k].fullname,
                    12
                  ) >
                width * 0.95
                  ? finalX -
                    helveticaFont.widthOfTextAtSize(
                      document.signs[k].fullname,
                      12
                    ) +
                    (15 / 100) * width
                  : finalX,
              y: finalY + 1,
              size: 12,
              font: helveticaFont,
              color: rgb(0, 0, 0),
              // rotate: degrees(-45),
            });
          }
        }

        const pdfDataUri = await newDoc.saveAsBase64({ dataUri: true });
        iframe.current.src = pdfDataUri;
        // setTimeout(
        //   () => iframe.current.scrollTo(0, Number.MAX_SAFE_INTEGER),
        //   500
        // );
      }
    };

    extractPdf();
  }, [document, positions, iframe]);

  const handleChange = id => (e, data) => {
    if (ref.current) {
      console.log(id, data, ref.current.clientWidth, ref.current.clientHeight);
      setPositions({
        ...positions,
        [id]: {
          x: Math.round((data.lastX / ref.current.clientWidth) * 100),
          realx: data.lastX,
          realy: data.lastY,
          y: Math.round(
            ((data.lastY +
              window.document.getElementById(`specimen-${id}`).clientHeight) /
              ref.current.clientHeight) *
              100
          ),
        },
      });
    }
  };

  return (
    <Dialog
      open={!!data}
      fullScreen
      TransitionProps={{ mountOnEnter: true, unmountOnExit: true }}
    >
      <DialogTitle>
        Pengaturan Posisi Tanda Tangan Untuk Dokumen "{data?.title}"
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={6}>
            <Box
              height="calc(100vh - 150px)"
              width="100%"
              bgcolor="primary.light"
              position="relative"
              ref={ref}
            >
              <Box
                height="100%"
                width="100%"
                position="absolute"
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                alignContent="center"
              >
                <Typography
                  style={{ userSelect: 'none' }}
                  variant="h4"
                  align="center"
                  component="div"
                >
                  ATUR POSISI TANDA TANGAN DI AREA INI
                </Typography>
                <Typography
                  style={{ userSelect: 'none' }}
                  align="center"
                  component="div"
                >
                  Pindahkan gambar tanda tangan ke posisi yang diinginkan, lalu
                  cek hasil pada PDF di sebelah kanan untuk kesesuaian
                </Typography>
                <Typography
                  style={{ userSelect: 'none' }}
                  variant="body2"
                  align="center"
                  component="div"
                >
                  *Skala area ini disesuaikan dengan kotak merah pada hasil PDF
                  di sebelah kanan
                </Typography>
              </Box>
              {signers.map(
                s =>
                  ref.current && (
                    <Draggable
                      bounds="parent"
                      onStop={handleChange(s.id)}
                      key={s.id}
                      defaultPosition={{
                        x: positions[s.id].x
                          ? (ref.current?.clientWidth || 0) *
                            (positions[s.id].x / 100)
                          : 0,
                        y: positions[s.id].y
                          ? positions[s.id].y / 100 === 1
                            ? (ref.current?.clientHeight || 0) - 140
                            : (ref.current?.clientHeight || 0) *
                              (positions[s.id].y / 100)
                          : (ref.current?.clientHeight || 0) - 140,
                      }}
                      // grid={[128, 128]}
                    >
                      {/* <Box width={128} height={128} bgcolor="red" position="absolute" /> */}
                      <Box
                        id={`specimen-${s.id}`}
                        display={'flex'}
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                        alignContent={'center'}
                        width="fit-content"
                        border={1}
                        borderColor="secondary.dark"
                        p={1}
                      >
                        <Box clone height={100} width={'auto'}>
                          <img
                            src={
                              s.specimen
                                ? `${process.env.REACT_APP_API_URL}/users/fn/specimen/${s.id}`
                                : 'https://via.placeholder.com/100.jpg?text=No Image'
                            }
                          />
                        </Box>
                        <Box clone fontSize={12}>
                          <Typography>{s.fullname}</Typography>
                        </Box>
                        <Box
                          position="absolute"
                          width="100%"
                          height="100%"
                          // onClick={e => e.preventDefault()}
                        />
                      </Box>
                    </Draggable>
                  )
              )}
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box
              component="iframe"
              width="100%"
              height="calc(100vh - 150px)"
              src={null}
              ref={iframe}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Batal</Button>
        <Button
          variant="contained"
          color="primary"
          onClick={onChange(positions)}
        >
          Simpan Pengaturan
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const PrintFilesDialog = ({
  open,
  onClose,
  attachments,
  type,
  mailId,
  signers = [],
  settings,
}) => {
  const notify = useNotify();
  const [custom, setCustom] = useState(null);

  const [{ loading }, post] = useAxios(
    {
      url: `${
        process.env.REACT_APP_API_URL
      }/mail/fn/print/${type.toLowerCase()}/${mailId}`,
      method: 'POST',
    },
    { manual: true }
  );

  const [printState, setPrintState] = useState(
    settings ||
      attachments
        .filter(a => a.mime === 'application/pdf')
        .map((a, idx) => ({
          ...a,
          order: idx,
          logoset: 'lpttn',
          useKop: true,
          useSign: true,
          useCode: true,
          useParaf: true,
          useQr: true,
          parafLastPage: false,
        }))
  );

  useEffect(() => {
    if (open) {
      setPrintState(
        settings ||
          attachments
            .filter(a => a.mime === 'application/pdf')
            .map((a, idx) => ({
              ...a,
              order: idx,
              logoset: 'lpttn',
              useKop: true,
              useSign: true,
              useCode: true,
              useParaf: true,
              useQr: true,
              parafLastPage: false,
            }))
      );
    }
  }, [open, settings, attachments, type, mailId]);

  const handleChange = (selected, key, logoset) => event =>
    setPrintState(
      [
        ...printState.filter(p => p.src !== selected.src),
        {
          ...selected,
          [key]: event.target.checked,
          ...(logoset ? { logoset } : {}),
        },
      ].sort((a, b) => a.order - b.order)
    );

  const handleCustom = selected => event => setCustom(selected);

  const handleSettings = settings => event => {
    setPrintState(
      [
        ...printState.filter(p => p.src !== custom.src),
        { ...custom, sign: settings },
      ].sort((a, b) => a.order - b.order)
    );
    setCustom(null);
  };

  const print = async () => {
    try {
      const res = await post({
        responseType: 'blob',
        data: printState.reduce((obj, p) => ({ ...obj, [p.path]: p }), {}),
      });
      if (res.data) {
        const filename = `${mailId}.pdf`;
        // notify(window.navigator.userAgent, 'info');
        if (window.navigator.userAgent.toLowerCase().includes('flutter')) {
          // const blob = new Blob([res.data], { type: 'application/pdf' });
          const url = URL.createObjectURL(res.data);
          if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(res.data, filename);
          } else {
            const a = window.document.createElement('a');
            document.body.appendChild(a);
            const url = window.URL.createObjectURL(res.data);
            a.href = url;
            a.download = filename;
            a.click();
            setTimeout(() => {
              window.URL.revokeObjectURL(url);
              document.body.removeChild(a);
            }, 0);
          }
        } else {
          saveAs(res.data, filename);
        }
        notify('Surat dan lampiran berhasil dicetak!', 'success');
      } else {
        notify('Gagal cetak!', 'error');
      }
    } catch (error) {
      console.error(error);
      notify('Gagal cetak!', 'error');
    }
  };

  return (
    <>
      <Dialog
        open={open}
        maxWidth="md"
        fullWidth
        TransitionProps={{ mountOnEnter: true, unmountOnExit: true }}
      >
        <DialogTitle>Cetak Surat dan Lampiran</DialogTitle>
        <DialogContent>
          <Typography variant="body2">
            Harap lakukan pengaturan format cetak terlebih dahulu untuk
            masing-masing dokumen
          </Typography>
          <List>
            {printState.length === 0 && (
              <ListItem>
                <ListItemText
                  primary="Belum ada file yang dapat dicetak"
                  secondary="Hanya file PDF yang dapat dicetak melalui menu ini"
                />
              </ListItem>
            )}
            {printState.map((p, idx) => (
              <ListItem key={idx}>
                <ListItemText
                  disableTypography
                  primary={
                    <Typography component="a" href={p.src} target="_blank">
                      {p.title}
                    </Typography>
                  }
                  secondary={
                    <Grid container spacing={1}>
                      <Grid item>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={p.useKop && p.logoset === 'lpttn'}
                              onChange={handleChange(p, 'useKop', 'lpttn')}
                            />
                          }
                          label="Kop surat LPTTN di halaman pertama"
                        />
                      </Grid>
                      <Grid item>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={p.useKop && p.logoset === 'yayasan'}
                              onChange={handleChange(p, 'useKop', 'yayasan')}
                            />
                          }
                          label="Kop surat Yayasan di halaman pertama"
                        />
                      </Grid>
                      <Grid item>
                        <Grid
                          container
                          alignItems="center"
                          alignContent="center"
                        >
                          <Grid item>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={p.useSign}
                                  onChange={handleChange(p, 'useSign')}
                                />
                              }
                              label="Tanda tangan surat di halaman terakhir"
                            />
                          </Grid>
                          {p.useSign && (
                            <Grid item>
                              <IconButton
                                size="small"
                                onClick={handleCustom(p)}
                                disabled={signers.length === 0}
                              >
                                <SettingsIcon />
                              </IconButton>
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                      <Grid item>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={p.useCode}
                              onChange={handleChange(p, 'useCode')}
                            />
                          }
                          label="Kode surat di setiap halaman"
                        />
                      </Grid>
                      <Grid item>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={p.useParaf}
                              onChange={handleChange(p, 'useParaf')}
                            />
                          }
                          label="Paraf di setiap halaman"
                        />
                      </Grid>
                      <Grid item>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={p.parafLastPage}
                              onChange={handleChange(p, 'parafLastPage')}
                            />
                          }
                          label="Paraf di halaman terakhir"
                        />
                      </Grid>
                      <Grid item>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={p.useQr}
                              onChange={handleChange(p, 'useQr')}
                            />
                          }
                          label="Kode QR di setiap halaman"
                        />
                      </Grid>
                    </Grid>
                  }
                />
                {/* <ListItemText
                  primary={p.title}
                  primaryTypographyProps={{
                    component: 'a',
                    href: p.src,
                    target: '_blank',
                  }}
                />
                <ListItemSecondaryAction>
                  <Grid
                    container
                    spacing={1}
                    alignItem="center"
                    alignContent="center">
                    <Grid item>
                      <TextField
                        select
                        margin="dense"
                        variant="outlined"
                        onChange={handleChange(p)}
                        value={p.style}>
                        <MenuItem value={'default'}>
                          Tanpa format sistem
                        </MenuItem>
                        <MenuItem value={'header-footer'}>
                          Dengan kop surat dan tanda tangan digital
                        </MenuItem>
                        <MenuItem value={'paraf'}>
                          Dengan paraf dan tanda tangan digital
                        </MenuItem>
                      </TextField>
                    </Grid>
                    {p.style === 'header-footer' && (
                      <Grid item>
                        <IconButton
                          onClick={handleCustom(p)}
                          disabled={signers.length === 0}>
                          <SettingsIcon />
                        </IconButton>
                      </Grid>
                    )}
                  </Grid>
                </ListItemSecondaryAction> */}
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} disabled={loading}>
            Batal
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={loading || printState.length === 0}
            onClick={print}
          >
            Proses &amp; Cetak
          </Button>
        </DialogActions>
      </Dialog>
      <CustomizePdfSign
        data={custom}
        signers={signers}
        onChange={handleSettings}
        onClose={handleCustom(null)}
      />
    </>
  );
};

export default PrintFilesDialog;
