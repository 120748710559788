import { SvgIcon } from '@material-ui/core';
const JenisSuratIcon = props => (
  <SvgIcon {...props}>
    <path
      opacity="0.5"
      d="M22 15.9402C22 18.7302 19.76 20.9902 16.97 21.0002H16.96H7.05C4.27 21.0002 2 18.7502 2 15.9602V15.9502C2 15.9502 2.006 11.5242 2.014 9.29824C2.015 8.88024 2.495 8.64624 2.822 8.90624C5.198 10.7912 9.447 14.2282 9.5 14.2732C10.21 14.8422 11.11 15.1632 12.03 15.1632C12.95 15.1632 13.85 14.8422 14.56 14.2622C14.613 14.2272 18.767 10.8932 21.179 8.97724C21.507 8.71624 21.989 8.95024 21.99 9.36724C22 11.5762 22 15.9402 22 15.9402"
      fillOpacity="0.4"
    />
    <path
      d="M21.4761 5.67363C20.6101 4.04163 18.9061 2.99963 17.0301 2.99963H7.05013C5.17413 2.99963 3.47013 4.04163 2.60413 5.67363C2.41013 6.03863 2.50213 6.49363 2.82513 6.75163L10.2501 12.6906C10.7701 13.1106 11.4001 13.3196 12.0301 13.3196C12.0341 13.3196 12.0371 13.3196 12.0401 13.3196C12.0431 13.3196 12.0471 13.3196 12.0501 13.3196C12.6801 13.3196 13.3101 13.1106 13.8301 12.6906L21.2551 6.75163C21.5781 6.49363 21.6701 6.03863 21.4761 5.67363"
      fillOpacity="0.4"
    />
  </SvgIcon>
);
export default JenisSuratIcon;
