import { Button, Card, Typography } from '@material-ui/core';
import moment from 'moment';
import { CompactForm, RaGrid } from 'ra-compact-ui';
import RichTextInput from 'ra-input-rich-text';
import React, { useState } from 'react';
import {
  AutocompleteArrayInput,
  AutocompleteInput,
  BooleanInput,
  Confirm,
  DateInput,
  Edit,
  FileField,
  FileInput,
  FormDataConsumer,
  RadioButtonGroupInput,
  ReferenceArrayInput,
  ReferenceInput,
  SelectArrayInput,
  SelectInput,
  TextInput,
  Toolbar,
  useGetIdentity,
  useNotify,
  useRedirect,
  useRefresh,
  useTranslate,
} from 'react-admin';

const Action = props => {
  const { handleSubmitWithRedirect, invalid, pristine, saving, redirect } =
    props;
  const translate = useTranslate();
  const refresh = useRefresh();
  const [open, setOpen] = useState(false);

  const handleDialog = () => setOpen(!open);

  const handleConfirm = async event => {
    event.preventDefault();
    setOpen(false);
    handleSubmitWithRedirect(redirect);
  };

  return (
    <>
      <Toolbar {...props}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleDialog}
          disabled={saving || pristine || invalid}
        >
          {translate('ra.action.edit')}
        </Button>
      </Toolbar>
      <Confirm
        isOpen={open && !saving}
        title="Simpan Perubahan?"
        content="Pastikan disposisi yang dipilih sudah benar"
        onConfirm={handleConfirm}
        onClose={handleDialog}
      />
    </>
  );
};

const EditDisposition = props => {
  const { initialValues, ...rest } = props;
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const {
    previous_disposition_id,
    previous_disposition_action,
    need_approval,
    classification,
    mail_id,
    require_all_response,
  } = initialValues;
  const { identity } = useGetIdentity();
  const handleSuccess = () => {
    notify('Disposisi berhasil diperbarui!', 'success');
    if (
      !!!previous_disposition_id
      //       ? 'list'
      // : `/${classification.toLowerCase()}_mails/${mail_id}/show`
    ) {
      redirect('list');
    } else {
      refresh();
    }
  };

  return (
    <Edit
      {...rest}
      component={previous_disposition_id ? 'div' : Card}
      title={' '}
      mutationMode="pessimistic"
      transform={values =>
        values.need_approval === true
          ? values.approve === true
            ? {
                ...values,
                type_dispositions: 'DITERUSKAN',
                disposisi_teruskan: {
                  user_ids: values.recipient_user_ids,
                  role_ids: values.recipient_role_ids,
                  division_ids: values.recipient_division_ids,
                },
                content: values.mail_content,
                attachments: values.mail_attachments,
              }
            : {
                ...values,
                type_dispositions: 'DITOLAK',
                disposisi_teruskan: {
                  user_ids: values.recipient_user_ids,
                  role_ids: values.recipient_role_ids,
                  division_ids: values.recipient_division_ids,
                },
                content: values.mail_content,
                attachments: values.mail_attachments,
              }
          : {
              ...values,
              type_dispositions: values.action,
              disposisi_teruskan: {
                user_ids: values.recipient_user_ids,
                role_ids: values.recipient_role_ids,
                division_ids: values.recipient_division_ids,
              },
              content: values.mail_content,
              attachments: values.mail_attachments,
            }
      }
      onSuccess={handleSuccess}
    >
      <CompactForm layoutComponents={[RaGrid]} toolbar={<Action />}>
        {!!!previous_disposition_id && (
          <RaGrid container spacing={1}>
            <RaGrid item xs={12} md={6}>
              <SelectInput
                fullWidth
                source="classification"
                label="Asal Surat"
                choices={[
                  { id: 'INCOMING', name: 'Surat Masuk' },
                  { id: 'OUTGOING', name: 'Surat Keluar' },
                  { id: 'INTERNAL', name: 'Surat Internal' },
                ]}
              />
            </RaGrid>
            <RaGrid item xs={12} md={6}>
              <FormDataConsumer>
                {({ formData }) =>
                  formData.classification && (
                    <ReferenceInput
                      fullWidth
                      reference={`${formData.classification.toLowerCase()}_mails`}
                      source="mail_id"
                      label="No. Surat"
                    >
                      <AutocompleteInput optionText="code" />
                    </ReferenceInput>
                  )
                }
              </FormDataConsumer>
            </RaGrid>
          </RaGrid>
        )}
        {need_approval === true ? (
          <RadioButtonGroupInput
            source="approve"
            choices={[
              { id: true, name: 'Ya' },
              { id: false, name: 'Tidak' },
            ]}
            label="Setujui?"
          />
        ) : (
          <FormDataConsumer>
            {({ formData }) => (
              <ReferenceInput
                fullWidth
                source="action"
                reference="enum_disposition_actions"
                label={`Disposisi${
                  require_all_response ? ' Untuk Pertimbangan' : ''
                }`}
                disabled={formData.disposition_allow_changes === false}
                // defaultValue={
                //   need_approval === true
                //     ? formData.approve === true
                //       ? { eq__id: 'DITERUSKAN' }
                //       : { eq__id: 'TOLAK' }
                //     : previous_disposition_action === 'REVIEW_TU'
                //     ? 'KOREKSI_TU'
                //     : previous_disposition_action === 'KOREKSI_TU'
                //     ? 'REVIEW_TU'
                //     : 'ARSIP'
                // }
                filter={
                  require_all_response === true
                    ? { in__id: ['PERTIMBANGAN_OK', 'PERTIMBANGAN_KHUSUS'] }
                    : previous_disposition_action === 'REVIEW_TU'
                    ? { eq__id: 'KOREKSI_TU' }
                    : previous_disposition_action === 'KOREKSI_TU'
                    ? { in__id: ['REVIEW_TU', 'DITERUSKAN'] }
                    : classification === 'OUTGOING'
                    ? {
                        nin__id: [
                          'PERTIMBANGAN_OK',
                          'PERTIMBANGAN_KHUSUS',
                          'KOREKSI_TU',
                          'REVIEW_TU',
                        ],
                      }
                    : {
                        nin__id: [
                          'PERTIMBANGAN_OK',
                          'PERTIMBANGAN_KHUSUS',
                          'KOREKSI_TU',
                          'REVIEW_TU',
                          'KIRIM',
                        ],
                      }
                }
              >
                <RadioButtonGroupInput optionText="description" />
              </ReferenceInput>
            )}
          </FormDataConsumer>
        )}
        <FormDataConsumer>
          {({ formData }) =>
            formData.action === 'DITERUSKAN' ? (
              <RaGrid container spacing={1}>
                {/* <RaGrid item xs={12} md={4}>
                  <ReferenceInput
                    source="recipient_user_ids"
                        reference="users"
                        label="Karyawan"
                        filter={{ active: true }}
                        sort={{ field: 'fullname', order: 'ASC' }}>
                        <AutocompleteArrayInput optionText="fullname" />
                  </ReferenceInput>
                </RaGrid> */}
                {/* <RaGrid item xs={12} md={4}>
                  <ReferenceInput
                    source="recipient_role_ids"
                        reference="roles"
                        label="Jabatan">
                        <AutocompleteArrayInput optionText="name" />
                  </ReferenceInput>
                    </RaGrid> */}
                <RaGrid item xs={12} md={6}>
                  <ReferenceArrayInput
                    source="recipient_division_ids"
                    reference="divisions"
                    label="Divisi"
                    disabled={formData.disposition_allow_changes === false}
                    filter={
                      formData.require_all_response
                        ? { eq__disallow_serial: true }
                        : {}
                    }
                  >
                    {formData.disposition_allow_changes === false ? (
                      <SelectArrayInput optionText="name" />
                    ) : (
                      <AutocompleteArrayInput optionText="name" />
                    )}
                  </ReferenceArrayInput>
                </RaGrid>
                {identity?.sysrole_id === 'SEKRETARIS' ? (
                  <RaGrid item xs={12} md={6}>
                    <BooleanInput
                      source="with_approval"
                      label="Pengajuan persetujuan?"
                    />
                    <BooleanInput
                      source="require_all_response"
                      label="Untuk Pertimbangan?"
                      helperText="*Pertimbangan memerlukan respon dari semua pihak untuk
                        bisa lanjut ke tahap berikutnya"
                    />
                  </RaGrid>
                ) : (
                  <></>
                )}
              </RaGrid>
            ) : formData.action === 'KIRIM' ? (
              <RaGrid container spacing={1}>
                <RaGrid item xs={12} md={6}>
                  <TextInput
                    fullWidth
                    label="No. Buku Agenda"
                    source="agenda_code"
                  />
                </RaGrid>
                <RaGrid item xs={12} md={6}>
                  <TextInput fullWidth label="Kode Surat" source="code" />
                </RaGrid>
                <RaGrid item xs={12} md={6}>
                  <DateInput fullWidth label="Tanggal Surat" source="date" />
                </RaGrid>
                <RaGrid item xs={12} md={6}>
                  <DateInput
                    fullWidth
                    label="Tanggal Input Surat"
                    source="input_date"
                    defaultValue={moment().format('YYYY-MM-DD')}
                  />
                </RaGrid>
              </RaGrid>
            ) : (
              <></>
            )
          }
        </FormDataConsumer>
        <TextInput
          source="action_note"
          label="Catatan Disposisi"
          multiline
          fullWidth
        />
        {previous_disposition_action === 'KOREKSI_TU' ? (
          <>
            <Typography variant="h6" gutterBottom>
              Perbaikan Surat
            </Typography>
            <RichTextInput
              fullWidth
              label="Isi Surat"
              source="mail_content"
              toolbar={[
                [{ font: [] }, { size: ['small', false, 'large', 'huge'] }],
                ['bold', 'italic', 'underline', 'strike'],
                [{ color: [] }, { background: [] }],
                [{ script: 'sub' }, { script: 'super' }],
                [{ header: 1 }, { header: 2 }, 'blockquote', 'code-block'],
                [
                  { list: 'ordered' },
                  { list: 'bullet' },
                  { indent: '-1' },
                  { indent: '+1' },
                ],
                [{ direction: 'rtl' }, { align: [] }],
                ['link', 'image', 'video', 'formula'],
                ['clean'],
              ]}
            />
            <FileInput
              source="mail_attachments"
              label="Lampiran Surat"
              multiple
            >
              <FileField source="src" title="title" target="_blank" />
            </FileInput>
          </>
        ) : (
          <></>
        )}
      </CompactForm>
    </Edit>
  );
};

export default EditDisposition;
