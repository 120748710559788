import {
  Box,
  Chip,
  Grid,
  List as MuiList,
  ListItem,
  makeStyles,
  styled,
  useMediaQuery,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React from 'react';
import {
  AutocompleteInput,
  CreateButton,
  DateInput,
  FilterButton,
  List,
  NullableBooleanInput,
  Pagination,
  ReferenceInput,
  sanitizeListRestProps,
  SortButton,
  TextInput,
  TopToolbar,
  useGetIdentity,
  useListContext,
} from 'react-admin';
import { useHistory } from 'react-router';
import { MailChips, MailDisposition, MailInfo } from 'shared/MailDetail';

const PrimaryLightChip = styled(Chip)(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  color: theme.palette.getContrastText(theme.palette.primary.light),
}));

const useStyles = makeStyles(theme => ({
  list: {
    backgroundColor: theme.palette.background.paper,
    width: '100%',
  },
  listItem: {
    margin: theme.spacing(1, 0),
    border: `1px solid ${theme.palette.primary.light}`,
    borderRadius: theme.shape.borderRadius,
  },
}));

const ListActions = ({ tab, ...props }) => {
  const { identity } = useGetIdentity();
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

  return (
    <TopToolbar {...sanitizeListRestProps(props)}>
      <SortButton
        fields={[
          'created_at',
          'input_date',
          'date',
          'title',
          'code',
          'agenda_code',
        ]}
      />
      <FilterButton />
      <CreateButton />
    </TopToolbar>
  );
};

const MailList = () => {
  const classes = useStyles();
  const history = useHistory();
  const { ids, data, basePath, loading, loaded, resource } = useListContext();
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

  const goTo = link => event => {
    event.preventDefault();

    history.push(link);
  };

  return (
    <>
      <MuiList className={classes.list}>
        {!loading &&
          ids.map(id => (
            <ListItem
              key={id}
              classes={{ root: classes.listItem }}
              button
              component="a"
              href={`${basePath}/${id}/show`}
              onClick={goTo(`${basePath}/${id}/show`)}
            >
              {/* <ListItemText
                disableTypography
                primary={<MailChips record={data[id]} resource={resource} />}
                secondary={<MailInfo record={data[id]} resource={resource} />}
              />
              <ListItemSecondaryAction>
                <MailDisposition record={data[id]} resource={resource} />
              </ListItemSecondaryAction> */}
              <Box my={1} width="100%">
                <Grid container justify="space-between">
                  <Grid item>
                    <MailChips record={data[id]} resource={resource} />
                    <MailInfo record={data[id]} resource={resource} />
                  </Grid>
                  {!isMobile && (
                    <Grid item>
                      <MailDisposition record={data[id]} resource={resource} />
                    </Grid>
                  )}
                </Grid>
              </Box>
            </ListItem>
          ))}
        {loading &&
          [...Array(5).keys()].map(k => (
            <ListItem key={k}>
              <Skeleton width="100%" height={128} />
            </ListItem>
          ))}
      </MuiList>
    </>
  );
};

const Filters = [
  <TextInput label="Search" source="q" alwaysOn />,
  <DateInput source="gte__date" label="Mulai dari tanggal" locales="id-ID" />,
  <DateInput source="lte__date" label="Sampai tanggal" locales="id-ID" />,
  <TextInput label="Judul Surat" source="title" />,
  <ReferenceInput
    source="eq__type"
    reference="enum_mail_types"
    label="Jenis Surat"
  >
    <AutocompleteInput optionText="description" />
  </ReferenceInput>,
  <ReferenceInput
    source="eq__category"
    reference="enum_mail_categories"
    label="Klasifikasi Surat"
  >
    <AutocompleteInput optionText="description" />
  </ReferenceInput>,
  <NullableBooleanInput
    source="eq__disposisi_to_me"
    label="Baru Masuk?"
    nullLabel="Tampilkan Semua Surat"
    fullWidth
  />,
];

const ListInternal = props => {
  return (
    <List
      {...props}
      exporter={false}
      component="div"
      bulkActionButtons={false}
      sort={{ field: 'date', order: 'DESC' }}
      title={'Surat Internal'}
      filters={Filters}
      filter={{ eq__includes_me: true }}
      perPage={50}
      pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}
      actions={<ListActions />}
    >
      <MailList />
    </List>
  );
};

export default ListInternal;
