import { Container } from '@material-ui/core';

import { CompactForm, RaGrid } from 'ra-compact-ui';
import { Create, SelectArrayInput, TextInput } from 'react-admin';

const CreateMailTypes = props => {
  return (
    <Container maxWidth="md" disableGutters>
      <Create {...props}>
        <CompactForm layoutComponents={[RaGrid]}>
          <TextInput fullWidth label="Kode Jenis Surat" source="code" />
          <TextInput
            fullWidth
            label="Deskripsi Jenis Surat"
            source="description"
          />
          <SelectArrayInput
            fullWidth
            source="classification"
            choices={[
              { id: 'INCOMING', name: 'Surat Masuk' },
              { id: 'INTERNAL', name: 'Surat Internal' },
              { id: 'OUTGOING', name: 'Surat Keluar' },
            ]}
            label="Muncul pada Dashboard"
          />
        </CompactForm>
      </Create>
    </Container>
  );
};

export default CreateMailTypes;
