import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Chip,
  CircularProgress,
  Grid,
  styled,
  Typography,
} from '@material-ui/core';
import AnnouncementIcon from '@material-ui/icons/AnnouncementOutlined';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import FolderIcon from '@material-ui/icons/FolderOutlined';
import PeopleIcon from '@material-ui/icons/PeopleOutlined';
import RateReviewIcon from '@material-ui/icons/RateReviewOutlined';
import SendIcon from '@material-ui/icons/SendOutlined';
import moment from 'moment';
import { useCallback, useMemo, useState } from 'react';
import { useGetList } from 'react-admin';
import Tree from 'react-d3-tree';

const icons = {
  ARSIP: <FolderIcon fontSize="small" />,
  BALAS: <SendIcon fontSize="small" />,
  DITERUSKAN: <PeopleIcon fontSize="small" />,
  REVIEW_TU: <RateReviewIcon fontSize="small" />,
  KOREKSI_TU: <AnnouncementIcon fontSize="small" />,
};

const DispositionCard = styled(Card)(
  ({ theme, data, currentDispositionId }) => {
    const bg =
      data.id === currentDispositionId
        ? theme.palette.secondary.light
        : data.action === 'DITERUSKAN'
        ? theme.palette.info.light
        : data.action === 'ARSIP'
        ? theme.palette.error.light
        : data.action === 'BALAS'
        ? theme.palette.warning.light
        : data.action === 'REVIEW_TU'
        ? theme.palette.warning.main
        : data.action === 'KOREKSI_TU'
        ? theme.palette.success.main
        : data.action === 'TOLAK'
        ? theme.palette.error.dark
        : theme.palette.background.paper;

    return {
      backgroundColor: bg,
      color: theme.palette.getContrastText(bg),
      maxWidth: 200,
      minWidth: 200,
    };
  }
);

const useCenteredTree = () => {
  const [translate, setTranslate] = useState({ x: 0, y: 0 });
  const containerRef = useCallback(containerElem => {
    if (containerElem !== null) {
      const { width, height } = containerElem.getBoundingClientRect();
      setTranslate({ x: width / 2, y: height / 2 });
    }
  }, []);
  return [translate, containerRef];
};

const getChildren = (parent_id, data, creator = null, identifier = null) => {
  const children = data.reduce(
    (obj, d) =>
      d.previous_disposition_id === parent_id &&
      (creator && identifier
        ? Array.isArray(creator)
          ? (identifier === 'user' ? creator.includes(d.created_by) : false) ||
            (identifier === 'division'
              ? creator.includes(d.created_by_division)
              : false) ||
            (identifier === 'role'
              ? creator.includes(d.created_by_role)
              : false)
          : (identifier === 'user' ? d.created_by === creator : false) ||
            (identifier === 'division'
              ? d.created_by_division === creator
              : false) ||
            (identifier === 'role' ? d.created_by_role === creator : false)
        : true)
        ? [...obj, { ...d, name: `${d.action}` }]
        : obj,
    []
  );

  return children.reduce((arr, c) => {
    if (
      c.action !== 'DITERUSKAN' &&
      c.action !== 'KOREKSI_TU' &&
      c.action !== 'REVIEW_TU'
    ) {
      return [
        ...arr,
        {
          ...c,
          name: `${c.action}`,
          children: [],
        },
      ];
    } else {
      return [
        ...arr,
        ...(c.recipient_users || []).map(u => ({
          ...c,
          name: `${c.action}`,
          receiver: u.name,
          children: getChildren(c.id, data, u.id, 'user'),
        })),
        ...(c.recipient_roles || []).map(r => ({
          ...c,
          name: `${c.action}`,
          receiver: r.name,
          children: getChildren(c.id, data, r.id, 'role'),
          // children: [],
        })),
        ...(c.recipient_divisions || []).map(dv => ({
          ...c,
          name: `${c.action}`,
          receiver: dv.name,
          children: getChildren(c.id, data, dv.id, 'division'),
          // children: [],
        })),
      ];
    }
  }, []);

  // return children.map(c => ({
  //   ...c,
  //   name: `${c.action}`,
  //   children: [
  //     ...c.recipient_users.map(u => getChildren(c.id, data, u.id)),
  //     // ...c.recipient_roles.map(r => getChildren(c.id, data)),
  //     // ...c.recipient_divisions.map(dv => getChildren(c.id, data)),
  //   ],
  // }));
};

const DispositionTree = ({ mailId, type, currentDispositionId }) => {
  const [translate, containerRef] = useCenteredTree();
  const { data, ids, loading, error } = useGetList(
    'mail_dispositions',
    { page: null, perPage: null },
    { field: 'id', order: 'ASC' },
    { mail_id: mailId, classification: type.toUpperCase() }
  );

  const treeData = useMemo(() => {
    if (!loading && !error) {
      const datas = ids.map(id => data[id]);
      const roots = datas.filter(d => d.previous_disposition_id === null);
      const childrens = datas.filter(d => d.previous_disposition_id !== null);

      return (
        roots
          // .sort((a, b) => a.previous_disposition_id - b.previous_disposition_id)
          .reduce(
            (obj, d) => {
              if (
                d.action !== 'DITERUSKAN' &&
                d.action !== 'KOREKSI_TU' &&
                d.action !== 'REVIEW_TU'
              ) {
                return {
                  ...obj,
                  children: [
                    ...obj.children,
                    {
                      ...d,
                      name: `${d.action}`,
                      children: [],
                    },
                  ],
                };
              } else {
                return {
                  ...obj,
                  children: [
                    ...obj.children,
                    ...d.recipient_users.map(u => ({
                      ...d,
                      name: `${d.action}`,
                      receiver: u.name,
                      children: getChildren(d.id, childrens, u.id, 'user'),
                      // children: [],
                    })),
                    ...d.recipient_roles.map(r => ({
                      ...d,
                      name: `${d.action}`,
                      receiver: r.name,
                      children: getChildren(d.id, datas, r.id, 'role'),
                      // children: [],
                    })),
                    ...d.recipient_divisions.map(dv => ({
                      ...d,
                      name: `${d.action}`,
                      receiver: dv.name,
                      children: getChildren(d.id, datas, dv.id, 'division'),
                      // children: [],
                    })),
                  ],
                };
              }
            },
            // d.recipient_users.length > 0
            //   ? {
            //       ...obj,
            //       children: [
            //         ...obj.children,
            //         ...d.recipient_users.map(u => ({
            //           ...d,
            //           name: `${d.action} - ${u.name}`,
            //           children: getChildren(d.id, datas, 'user', u.id),
            //         })),
            //       ],
            //     }
            //   : d.recipient_roles.length > 0
            //   ? {
            //       ...obj,
            //       children: [
            //         ...obj.children,
            //         ...d.recipient_roles.map(r => ({
            //           ...d,
            //           name: `${d.action} - ${r.name}`,
            //           children: getChildren(d.id, datas, 'role', r.id),
            //         })),
            //       ],
            //     }
            //   : d.recipient_divisions.length > 0
            //   ? {
            //       ...obj,
            //       children: [
            //         ...obj.children,
            //         ...d.recipient_divisions.map(dv => ({
            //           ...d,
            //           name: `${d.action} - ${dv.name}`,
            //           children: getChildren(d.id, datas, 'division', dv.id),
            //         })),
            //       ],
            //     }
            //       :,
            {
              ...(roots || [])[0],
              name: 'Surat Masuk Sistem',
              children: [],
              action: 'NEW',
            }
          )
      );
    }
    return { name: 'Loading...', children: [] };
  }, [loading, error, data, ids]);

  // console.log(treeData);

  return (
    <Box height={384} ref={containerRef}>
      {!loading && !error ? (
        <Tree
          data={treeData}
          translate={translate}
          orientation="vertical"
          pathFunc="step"
          nodeSize={{ x: 256, y: 256 + 128 }}
          renderCustomNodeElement={props => (
            <g>
              <foreignObject
                width={384}
                height={256 + 128}
                style={{
                  transform: 'translate(-192px, -20px)',
                }}
              >
                <Grid
                  container
                  justify="center"
                  alignItems="center"
                  alignContent="center"
                  direction="column"
                >
                  <Grid item>
                    {props.nodeDatum.previous_disposition_id ? (
                      <ArrowDownwardIcon fontSize="large" color="primary" />
                    ) : (
                      <FiberManualRecordIcon fontSize="large" color="primary" />
                    )}
                  </Grid>
                  <Grid item>
                    <Box clone marginBottom={1}>
                      <Chip
                        label={moment(props.nodeDatum.created_at).format(
                          'DD MMM YY HH:mm'
                        )}
                      />
                    </Box>
                  </Grid>
                  <Grid item>
                    <DispositionCard
                      data={props.nodeDatum}
                      currentDispositionId={currentDispositionId}
                    >
                      <CardHeader
                        avatar={icons[props.nodeDatum.action]}
                        title={
                          <>
                            {props.nodeDatum.name}
                            {props.nodeDatum.receiver && (
                              <>
                                <br />
                                {props.nodeDatum.receiver}
                              </>
                            )}
                          </>
                        }
                        titleTypographyProps={{ variant: 'subtitle2' }}
                        subheader={props.nodeDatum.creator || '?'}
                        subheaderTypographyProps={{
                          variant: 'body2',
                          color: 'textSecondary',
                        }}
                      />
                      {props.nodeDatum.action_note && (
                        <CardContent>
                          <Typography>{props.nodeDatum.action_note}</Typography>
                        </CardContent>
                      )}
                    </DispositionCard>
                  </Grid>
                </Grid>
              </foreignObject>
            </g>
          )}
        />
      ) : (
        <Box>
          <CircularProgress variant="indeterminate" />
        </Box>
      )}
    </Box>
  );
};

export default DispositionTree;
