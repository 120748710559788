import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  styled,
  TextField,
  Typography,
} from '@material-ui/core';
import { fade, ThemeProvider } from '@material-ui/core/styles';
import { Skeleton } from '@material-ui/lab';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Notification, useCreate, useGetOne, useNotify } from 'react-admin';
import useGeolocation from 'react-hook-geolocation';
import { useParams } from 'react-router';
import theme from './theme';

const BgBox = styled(Box)(({ theme }) => ({
  backgroundImage: `linear-gradient(${fade(
    theme.palette.primary.dark,
    0.95
  )}, ${fade(
    theme.palette.secondary.light,
    0.25
  )}), url(https://source.unsplash.com/1600x900/?nature)`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
}));

const VerificationInfo = ({ classification, id }) => {
  const notify = useNotify();
  const { data, loading, error } = useGetOne(`${classification}_mails`, id);

  useEffect(() => {
    if (!loading && error) {
      notify(error);
    }
  }, [loading, error, notify]);

  return (
    <Card>
      <CardHeader
        title="Registrasi Surat LPTTN"
        avatar={<Avatar src={'/logo.png'} />}
      />
      <CardContent>
        <Box marginBottom={1}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle2" color="textSecondary">
                No. Surat
              </Typography>
              <Typography>
                {!loading && !error ? data.code : <Skeleton />}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle2" color="textSecondary">
                Tanggal
              </Typography>
              <Typography>
                {!loading && !error ? (
                  moment(data.created_at).format('DD MMM YYYY')
                ) : (
                  <Skeleton />
                )}
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Typography variant="subtitle2" color="textSecondary">
          Judul
        </Typography>
        <Typography gutterBottom>
          {!loading && !error ? data.title : <Skeleton />}
        </Typography>
        <Box marginBottom={1}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle2" color="textSecondary">
                Kepada
              </Typography>
              <Box clone whiteSpace="pre-wrap">
                <Typography>
                  {!loading && !error ? data.to : <Skeleton />}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle2" color="textSecondary">
                Dari
              </Typography>
              <Box clone whiteSpace="pre-wrap">
                <Typography>
                  {!loading && !error ? data.from : <Skeleton />}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box my={2}>
          <Divider />
        </Box>
        {(data?.approvers || []).length > 0 && (
          <>
            <Typography variant="subtitle2" color="textSecondary">
              Daftar Penanda Tangan
            </Typography>
            <List dense>
              {!loading && !error
                ? data.approvers.map(d => (
                    <ListItem key={d.id}>
                      <ListItemText primary={d.fullname} secondary={d.role} />
                    </ListItem>
                  ))
                : [...Array(2).keys()].map(k => (
                    <ListItem key={k}>
                      <ListItemText
                        primary={<Skeleton />}
                        secondary={<Skeleton />}
                      />
                    </ListItem>
                  ))}
            </List>
          </>
        )}
        {(data?.parafs || []).length > 0 && (
          <>
            <Typography variant="subtitle2" color="textSecondary">
              Daftar Paraf
            </Typography>
            <List dense>
              {!loading && !error
                ? data.parafs.map(d => (
                    <ListItem key={d.id}>
                      <ListItemText primary={d.fullname} secondary={d.role} />
                    </ListItem>
                  ))
                : [...Array(2).keys()].map(k => (
                    <ListItem key={k}>
                      <ListItemText
                        primary={<Skeleton />}
                        secondary={<Skeleton />}
                      />
                    </ListItem>
                  ))}
            </List>
          </>
        )}
      </CardContent>
    </Card>
  );
};

const VerifyPage = ({}) => {
  const geolocation = useGeolocation();
  const [name, setName] = useState('');
  const [from, setFrom] = useState('');
  const [isGuest, setIsGuest] = useState(false);
  const notify = useNotify();
  const { classification, id } = useParams();
  const [create, { loading, error }] = useCreate();

  useEffect(() => {
    if (window.sessionStorage.getItem('guest_id')) {
      setIsGuest(true);
    }
  }, []);

  useEffect(() => {
    if (!loading && error) {
      notify(error);
    }
  }, [loading, error, notify]);

  const submit = e => {
    e.preventDefault();
    create(
      'mail_verification_guests',
      {
        name,
        from,
        classification,
        mail_id: id,
        ...(!geolocation.error
          ? { location: `(${geolocation.longitude},${geolocation.latitude})` }
          : {}),
      },
      {
        onSuccess: ({ data }) => {
          notify('Terima kasih telah mengisi buku tamu');
          window.sessionStorage.setItem('guest_id', data.id);
          setIsGuest(true);
        },
        onFailure: error =>
          notify(error, {
            type: 'warning',
          }),
      }
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <BgBox width="100vw" height="100vh" bgcolor="primary.dark" py={4}>
        <Container maxWidth="md">
          {isGuest ? (
            <VerificationInfo {...{ classification, id }} />
          ) : (
            <Card component="form" onSubmit={submit}>
              <CardHeader
                title="Registrasi Surat LPTTN"
                subheader="Harap isi buku tamu terlebih dahulu"
                avatar={<Avatar src={'/logo.png'} />}
              />
              <CardContent>
                <Grid container spacing={2} direction="column">
                  <Grid item>
                    <TextField
                      label="Nama Lengkap"
                      name="name"
                      type="text"
                      value={name}
                      variant="outlined"
                      fullWidth
                      onChange={e => setName(e.target.value)}
                      required
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      label="Asal Instansi"
                      name="from"
                      type="text"
                      value={from}
                      variant="outlined"
                      fullWidth
                      onChange={e => setFrom(e.target.value)}
                      required
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <CardActions>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Button
                      fullWidth
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={loading}
                    >
                      Cek Registrasi Surat
                    </Button>
                  </Grid>
                </Grid>
              </CardActions>
            </Card>
          )}
        </Container>
      </BgBox>
      <Notification />
    </ThemeProvider>
  );
};

export default VerifyPage;
