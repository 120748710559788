import { Container, Box } from '@material-ui/core';
import {
  Edit,
  SimpleForm,
  TextInput,
  ReferenceInput,
  AutocompleteInput,
  PasswordInput,
  ImageInput,
  ImageField,
  Toolbar,
  SaveButton,
  DeleteButton,
  BooleanField,
  BooleanInput,
  DeleteWithConfirmButton,
  useRecordContext,
} from 'react-admin';

import { CompactForm, RaGrid } from 'ra-compact-ui';

const Avatar = ({ record, source }) => {
  if (typeof record == 'string') {
    record = {
      [source]: record,
    };
  }
  return <ImageField record={record} source={source} />;
};

const Specimen = ({ record, source }) => {
  if (typeof record == 'string') {
    record = {
      [source]: record,
    };
  }
  return <ImageField record={record} source={source} />;
};

const Paraf = ({ record, source }) => {
  if (typeof record == 'string') {
    record = {
      [source]: record,
    };
  }
  return <ImageField record={record} source={source} />;
};

export const UserEditToolbar = props => {
  const record = useRecordContext();
  return (
    <Box clone justifyContent="space-between">
      <Toolbar {...props}>
        <SaveButton label="Simpan" />
        <DeleteWithConfirmButton
          label={record.active === true ? 'Nonaktifkan' : 'Aktifkan Kembali'}
          confirmTitle={
            record.active === true
              ? 'ra.message.delete_title'
              : 'Aktifkan Kembali Akun ini?'
          }
          confirmContent={
            record.active === true
              ? 'ra.message.delete_content'
              : 'User ini dapat kembali login dan menggunakan aplikasi'
          }
          mutationMode="pessimistic"
        />
      </Toolbar>
    </Box>
  );
};
const EditUser = props => (
  <Container maxWidth="md" disableGutters>
    <Edit {...props} mutationMode="pessimistic">
      <CompactForm layoutComponents={[RaGrid]} toolbar={<UserEditToolbar />}>
        <ImageInput source="avatar" label="Foto" accept="image/*">
          <Avatar source="src" />
        </ImageInput>
        <RaGrid container spacing={1} alignContent="center" alignItems="center">
          <RaGrid item xs={12} md={4}>
            <TextInput fullWidth source="username" />
          </RaGrid>
          <RaGrid item xs={12} md={4}>
            <TextInput fullWidth source="email" />
          </RaGrid>
          <RaGrid item xs={12} md={4}>
            <BooleanInput
              fullWidth
              source="allow_receive_email"
              label="Dapat menerima notifikasi via email?"
            />
          </RaGrid>
        </RaGrid>
        <TextInput fullWidth source="fullname" label="Nama Karyawan" />
        <ReferenceInput
          source="role_id"
          reference="roles"
          label="Jabatan"
          fullWidth
        >
          <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput
          source="sysrole_id"
          reference="system_roles"
          label="Hak Akses Pengguna"
          fullWidth
        >
          <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <PasswordInput fullWidth source="new_pass" label="Password Baru" />
        <RaGrid container spacing={1}>
          <RaGrid item xs={12} md={6}>
            <ImageInput source="specimen" label="Tanda Tangan" accept="image/*">
              <Specimen source="src" />
            </ImageInput>
          </RaGrid>
          <RaGrid item xs={12} md={6}>
            <ImageInput source="paraf" label="Paraf" accept="image/*">
              <Paraf source="src" />
            </ImageInput>
          </RaGrid>
        </RaGrid>
      </CompactForm>
    </Edit>
  </Container>
);

export default EditUser;
