import { Button, Container } from '@material-ui/core';
import arrayMutators from 'final-form-arrays';
import { CompactForm, RaGrid } from 'ra-compact-ui';
import RichTextInput from 'ra-input-rich-text';
import React, { useEffect, useState } from 'react';
import {
  AutocompleteArrayInput,
  BooleanInput,
  Confirm,
  DateInput,
  DeleteButton,
  Edit,
  FileField,
  FileInput,
  FormDataConsumer,
  RadioButtonGroupInput,
  ReferenceArrayInput,
  ReferenceField,
  ReferenceInput,
  SelectArrayInput,
  SelectInput,
  TextField,
  TextInput,
  Toolbar,
  useGetIdentity,
  useGetOne,
  useRefresh,
  useTranslate,
} from 'react-admin';
import { useForm, useFormState } from 'react-final-form';

const Action = props => {
  const { handleSubmitWithRedirect, invalid, pristine, saving, redirect } =
    props;
  const translate = useTranslate();
  const refresh = useRefresh();
  const [open, setOpen] = useState(false);

  const handleDialog = () => setOpen(!open);

  const handleConfirm = async event => {
    event.preventDefault();
    setOpen(false);
    handleSubmitWithRedirect(redirect);
  };

  return (
    <>
      <Toolbar {...props} style={{ justifyContent: 'space-between' }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleDialog}
          disabled={saving || pristine || invalid}
        >
          {translate('ra.action.edit')}
        </Button>
        <DeleteButton />
      </Toolbar>
      <Confirm
        isOpen={open && !saving}
        title="Simpan Perubahan?"
        content="Pastikan disposisi yang dipilih sudah benar"
        onConfirm={handleConfirm}
        onClose={handleDialog}
      />
    </>
  );
};

const mutators = {
  ...arrayMutators,
  setTemplate: ([name, content], state, { changeValue }) => {
    changeValue(state, name, () => content);
  },
};

const GetTemplate = ({}) => {
  const form = useForm();
  const { values } = useFormState();
  const { data, loading, error } = useGetOne(
    'master_mail_templates',
    values?.template_id,
    {
      enabled: !!values?.template_id,
    }
  );
  useEffect(() => {
    if (!loading && !error && data) {
      form.mutators.setTemplate('content', data.content);
    }
  }, [loading, error, data]);
  return null;
};

const EditInternal = props => {
  const { identity } = useGetIdentity();
  return (
    <Container maxWidth="md" disableGutters>
      <Edit {...props} mutationMode="pessimistic">
        <CompactForm
          mutators={mutators}
          layoutComponents={[RaGrid]}
          toolbar={<Action />}
        >
          <BooleanInput label="Penting?" source="urgent" />
          <TextInput fullWidth label="Judul Surat" source="title" />
          <TextInput fullWidth label="Surat Ditujukan Kepada" source="to" />
          <RaGrid container spacing={1}>
            <RaGrid item xs={12} md={6}>
              <DateInput fullWidth label="Tanggal Surat" source="date" />
            </RaGrid>
            <RaGrid item xs={12} md={6}>
              <DateInput
                fullWidth
                label="Tanggal Input Surat"
                source="input_date"
              />
            </RaGrid>
            <RaGrid item xs={12} md={6}>
              <ReferenceField
                link={false}
                source="category"
                reference="enum_mail_categories"
                label="Kategori Surat"
              >
                <TextField source="description" />
              </ReferenceField>
            </RaGrid>
            <RaGrid item xs={12} md={6}>
              <ReferenceField
                link={false}
                source="type"
                reference="enum_mail_types"
                label="Tipe Surat"
              >
                <TextField source="description" />
              </ReferenceField>
            </RaGrid>
          </RaGrid>
          <TextField label="Kode Surat" source="code" />
          <ReferenceInput
            allowEmpty
            fullWidth
            label="Pakai Template"
            source="template_id"
            reference="master_mail_templates"
          >
            <SelectInput optionText="name" />
          </ReferenceInput>
          <GetTemplate />
          <RichTextInput
            fullWidth
            label="Isi Surat"
            source="content"
            toolbar={[
              [{ font: [] }, { size: ['small', false, 'large', 'huge'] }],
              ['bold', 'italic', 'underline', 'strike'],
              [{ color: [] }, { background: [] }],
              [{ script: 'sub' }, { script: 'super' }],
              [{ header: 1 }, { header: 2 }, 'blockquote', 'code-block'],
              [
                { list: 'ordered' },
                { list: 'bullet' },
                { indent: '-1' },
                { indent: '+1' },
              ],
              [{ direction: 'rtl' }, { align: [] }],
              ['link', 'image', 'video', 'formula'],
              ['clean'],
            ]}
          />
          <TextInput source="note" label="Catatan" multiline fullWidth />
          <FileInput source="attachments" label="Attachments" multiple>
            <FileField source="src" title="title" target="_blank" />
          </FileInput>
          <FormDataConsumer>
            {({ formData }) => (
              <ReferenceInput
                fullWidth
                source="disposition_start.action"
                reference="enum_disposition_actions"
                label="Disposisi"
                filter={{ in__id: ['REVIEW_TU', 'DITERUSKAN'] }}
                disabled={formData.disposition_allow_changes === false}
              >
                <RadioButtonGroupInput optionText="description" />
              </ReferenceInput>
            )}
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData }) =>
              formData.disposition_start?.action === 'DITERUSKAN' ? (
                <RaGrid container spacing={1}>
                  <RaGrid item xs={12} md={4}>
                    <ReferenceArrayInput
                      source="disposition_start.recipient_division_ids"
                      reference="divisions"
                      label="Divisi"
                      disabled={formData.disposition_allow_changes === false}
                      filter={
                        formData.disposition_start?.require_all_response
                          ? { eq__disallow_serial: true }
                          : {}
                      }
                    >
                      {formData.disposition_allow_changes === false ? (
                        <SelectArrayInput optionText="name" />
                      ) : (
                        <AutocompleteArrayInput optionText="name" />
                      )}
                    </ReferenceArrayInput>
                  </RaGrid>
                  {identity?.sysrole_id === 'SEKRETARIS' ? (
                    <RaGrid item xs={12} md={6}>
                      <BooleanInput
                        disabled={formData.disposition_allow_changes === false}
                        source="disposition_start.require_all_response"
                        label="Untuk Pertimbangan?"
                        helperText="*Pertimbangan memerlukan respon dari semua pihak untuk
                        bisa lanjut ke tahap berikutnya"
                      />
                    </RaGrid>
                  ) : (
                    <></>
                  )}
                </RaGrid>
              ) : (
                <></>
              )
            }
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData }) => (
              <TextInput
                source="disposition_start.action_note"
                label="Catatan Disposisi"
                multiline
                fullWidth
                disabled={formData.disposition_allow_changes === false}
              />
            )}
          </FormDataConsumer>
        </CompactForm>
      </Edit>
    </Container>
  );
};

export default EditInternal;
