import { Container } from '@material-ui/core';
import { CompactForm, RaGrid } from 'ra-compact-ui';
import React from 'react';
import { Edit, SelectArrayInput, TextInput } from 'react-admin';

const EditTypes = props => {
  return (
    <Container maxWidth="md" disableGutters>
      <Edit {...props}>
        <CompactForm layoutComponents={[RaGrid]}>
          <TextInput fullWidth label="Kode Jenis Surat" source="code" />
          <TextInput fullWidth label="Deskripsi" source="description" />
          <SelectArrayInput
            fullWidth
            source="classification"
            choices={[
              { id: 'INCOMING', name: 'Surat Masuk' },
              { id: 'INTERNAL', name: 'Surat Internal' },
              { id: 'OUTGOING', name: 'Surat Keluar' },
            ]}
            label="Muncul pada Dashboard"
          />
        </CompactForm>
      </Edit>
    </Container>
  );
};

export default EditTypes;
