import { Container } from '@material-ui/core';
import { CompactForm, RaGrid } from 'ra-compact-ui';
import RichTextInput from 'ra-input-rich-text';
import React from 'react';
import { Edit, TextInput } from 'react-admin';

const EditTemplates = props => {
  return (
    <Container maxWidth="md" disableGutters>
      <Edit {...props}>
        <CompactForm layoutComponents={[RaGrid]}>
          <TextInput fullWidth label="Nama Template" source="name" />
          <RichTextInput
            fullWidth
            label="Isi Template"
            source="content"
            toolbar={[
              [{ font: [] }, { size: ['small', false, 'large', 'huge'] }], // custom dropdown
              ['bold', 'italic', 'underline', 'strike'],
              [{ color: [] }, { background: [] }],
              [{ script: 'sub' }, { script: 'super' }],
              [{ header: 1 }, { header: 2 }, 'blockquote', 'code-block'],
              [
                { list: 'ordered' },
                { list: 'bullet' },
                { indent: '-1' },
                { indent: '+1' },
              ],
              [{ direction: 'rtl' }, { align: [] }],
              ['link', 'image', 'video', 'formula'],
              ['clean'],
            ]}
          />
        </CompactForm>
      </Edit>
    </Container>
  );
};

export default EditTemplates;
