import { Grid, useMediaQuery } from '@material-ui/core';
import {
  Datagrid,
  EditButton,
  Filter,
  FunctionField,
  List,
  ReferenceField,
  SimpleList,
  TextField,
  TextInput,
} from 'react-admin';

const GridFilters = props => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
  </Filter>
);

const ListRoles = props => {
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

  return (
    <List {...props} filters={<GridFilters />} exporter={false}>
      {isSmall ? (
        <SimpleList
          primaryText={record => record.name}
          secondaryText={record => (
            <Grid container spacing={1}>
              {record.parent_role_id && (
                <Grid item>
                  <ReferenceField
                    record={record}
                    source="parent_role_id"
                    reference="roles"
                    link={false}
                  >
                    <FunctionField
                      render={subrecord => `Dibawah jabatan ${subrecord.name},`}
                    />
                  </ReferenceField>
                </Grid>
              )}
              {record.division_id && (
                <Grid item>
                  <ReferenceField
                    record={record}
                    source="division_id"
                    reference="divisions"
                    link={false}
                  >
                    <FunctionField
                      render={subrecord => `Divisi ${subrecord.name}`}
                    />
                  </ReferenceField>
                </Grid>
              )}
            </Grid>
          )}
          linkType="edit"
        />
      ) : (
        <Datagrid>
          <TextField source="name" label="Nama Jabatan" />
          <ReferenceField
            source="parent_role_id"
            reference="roles"
            label="Dibawah Jabatan"
            link={false}
          >
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField
            source="division_id"
            reference="divisions"
            label="Divisi"
          >
            <TextField source="name" />
          </ReferenceField>
          <EditButton />
        </Datagrid>
      )}
    </List>
  );
};

export default ListRoles;
