import { Container } from '@material-ui/core';
import { CompactForm, RaGrid } from 'ra-compact-ui';
import React from 'react';
import {
  AutocompleteInput,
  Edit,
  FormDataConsumer,
  ReferenceInput,
  TextInput,
} from 'react-admin';

const EditDivisions = props => {
  return (
    <Container maxWidth="md" disableGutters>
      <Edit {...props}>
        <CompactForm layoutComponents={[RaGrid]}>
          <TextInput fullWidth label="Nama Divisi" source="name" />
          <FormDataConsumer>
            {({ formData }) => (
              <ReferenceInput
                source="parent_division_id"
                reference="divisions"
                label="Dibawah Divisi"
                filter={{ neq__id: formData.id }}
                allowEmpty
                fullWidth
              >
                <AutocompleteInput optionText="name" />
              </ReferenceInput>
            )}
          </FormDataConsumer>
        </CompactForm>
      </Edit>
    </Container>
  );
};

export default EditDivisions;
