import { Typography, useMediaQuery } from '@material-ui/core';
import { AvatarField } from 'ra-compact-ui';
import {
  BooleanField,
  BooleanInput,
  Datagrid,
  EditButton,
  Filter,
  List,
  SimpleList,
  TextField,
  TextInput,
} from 'react-admin';

const GridFilters = props => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
    <BooleanInput label="Active?" source="active" alwaysOn />
  </Filter>
);

const ListUser = props => {
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
  return (
    <List {...props} filters={<GridFilters />}>
      {isSmall ? (
        <SimpleList
          primaryText={record => record.username}
          secondaryText={record => (
            <>
              <Typography variant="body2">
                Nama Karyawan: {record.fullname}
              </Typography>
              <Typography variant="body2">
                Jabatan : {record.jabatan ? record.jabatan : '-'}
              </Typography>
            </>
          )}
          linkType="edit"
        />
      ) : (
        <Datagrid>
          <AvatarField source="avatar" title="Foto" label="Foto" />
          <TextField source="username" label="Username" />
          <TextField source="email" label="Email" />
          <TextField source="fullname" label="Nama Karyawan" />
          <TextField source="jabatan" label="Jabatan" />
          <TextField source="hak_akses" label="Hak Akses" />
          <BooleanField source="active" label="Akun Aktif?" />
          <EditButton />
        </Datagrid>
      )}
    </List>
  );
};

export default ListUser;
