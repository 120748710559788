import {
  Box,
  Divider,
  Drawer,
  Grid,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import React, { useCallback, useState } from 'react';
import {
  AutocompleteInput,
  CreateButton,
  Datagrid,
  DateField,
  DateInput,
  EditButton,
  FilterButton,
  FunctionField,
  List,
  ReferenceField,
  ReferenceInput,
  sanitizeListRestProps,
  SimpleList,
  TextField,
  TextInput,
  TopToolbar,
  useGetIdentity,
} from 'react-admin';
import { Route, useHistory } from 'react-router-dom';
import EditIncoming from 'resources/incoming/edit';
import EditInternal from 'resources/internal/edit';
import EditOutgoing from 'resources/outgoing/edit';

const ListActions = ({ tab, ...props }) => {
  return (
    <TopToolbar {...sanitizeListRestProps(props)}>
      <FilterButton />
      {tab === 'surat_dibuat' && <CreateButton />}
    </TopToolbar>
  );
};

const SmallText = withStyles({
  root: {
    fontSize: '14px',
  },
})(Typography);

const mailFilters = [
  <TextInput label="Search" source="q" alwaysOn />,
  <DateInput
    source="gte__date"
    style={{ width: '200px' }}
    label="Mulai dari tanggal"
    locales="id-ID"
    alwaysOn
  />,
  <DateInput
    source="lte__date"
    style={{ width: '200px' }}
    label="Sampai tanggal"
    locales="id-ID"
    alwaysOn
  />,
  <TextInput label="Judul Surat" source="title" />,
  <ReferenceInput source="type" reference="enum_mail_types" label="Jenis Surat">
    <AutocompleteInput optionText="description" />
  </ReferenceInput>,
  <ReferenceInput
    source="category"
    reference="enum_mail_categories"
    label="Klasifikasi Surat"
  >
    <AutocompleteInput optionText="description" />
  </ReferenceInput>,
];

const mobFilters = [
  <TextInput label="Search" source="q" />,
  <TextInput label="Judul Surat" source="title" />,
  <ReferenceInput source="type" reference="enum_mail_types" label="Jenis Surat">
    <AutocompleteInput optionText="description" />
  </ReferenceInput>,
  <ReferenceInput
    source="category"
    reference="enum_mail_categories"
    label="Klasifikasi Surat"
  >
    <AutocompleteInput optionText="description" />
  </ReferenceInput>,
];

const PrimaryMobile = ({ param }) => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={6}>
        <SmallText variant="span">
          <b>{param.from}</b>
        </SmallText>
      </Grid>
      <Grid item xs={6} style={{ textAlign: 'right' }}>
        <SmallText variant="span">
          {moment(param.date).format('DD-MM-YYYY')}
        </SmallText>
      </Grid>
      <Grid item xs={12}>
        <SmallText variant="span">
          <b>{param.title}</b>
        </SmallText>
      </Grid>
      <Grid item xs={12}>
        <SmallText variant="span">{param.code}</SmallText>
      </Grid>
    </Grid>
  );
};

const LayoutMobile = ({ param }) => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <SmallText>Kepada : {param.to}</SmallText>
      </Grid>
      <Grid item xs={12}>
        <Divider style={{ backgroundColor: '#ddd' }} />
      </Grid>
    </Grid>
  );
};

const MailColumn = ({ param }) => {
  return (
    <>
      <Box sx={{ mb: 1 }}>
        <b>Judul Surat :</b>
        <br />
        {param.title}
      </Box>

      <Box sx={{ mb: 1 }}>
        <b>Pengirim Surat :</b>
        <br />
        {param.from}
      </Box>
      <Box sx={{ mb: 1 }}>
        <b>Ditujukan Kepada :</b>
        <br />
        {param.to}
      </Box>
      <Box sx={{ mb: 1 }}>
        <b>Prihal :</b>
        <br />
        {param.note}
      </Box>
    </>
  );
};

const EditComponent = props => {
  const { resource } = props;

  if (resource === 'incoming_mails') {
    return <EditIncoming {...props} />;
  } else if (resource === 'internal_mails') {
    return <EditInternal {...props} />;
  } else if (resource === 'outgoing_mails') {
    return <EditOutgoing {...props} />;
  }
};

const ListAllMail = props => {
  const { resource } = props;
  const history = useHistory();
  const { identity } = useGetIdentity();
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

  const handleClose = useCallback(() => {
    history.push(`/${resource}`);
  }, [history, resource]);

  const [tab, setTab] = useState('incoming');
  const handleTabChange = (e, v) => setTab(v);

  return (
    <>
      <Route path={`/${resource}/${tab}/:id`}>
        {({ match, ...rest }) => {
          const isEdit = !!(
            match &&
            match.params &&
            match.params.id !== 'create' &&
            match.isExact
          );

          return (
            <>
              <Tabs value={tab} onChange={handleTabChange} variant="fullWidth">
                <Tab value="incoming" label="Masuk" />
                <Tab value="internal" label="Internal" />
                <Tab value="outgoing" label="Keluar" />
              </Tabs>
              {!isEdit && (
                <List
                  {...props}
                  exporter={false}
                  resource={`${tab}_mails`}
                  bulkActionButtons={false}
                  sort={{ field: 'date', order: 'DESC' }}
                  title={!isEdit ? 'Semua Surat' : ' '}
                  // actions={<ListActions tab={tab} />}
                  filters={isSmall ? mobFilters : mailFilters}
                >
                  {isSmall ? (
                    <SimpleList
                      primaryText={record => <PrimaryMobile param={record} />}
                      secondaryText={record => <LayoutMobile param={record} />}
                      linkType={'edit'}
                    />
                  ) : (
                    <Datagrid>
                      <TextField source="agenda_code" label="No Buku Agenda" />
                      <FunctionField
                        label="Urgensi"
                        render={record =>
                          `${record.urgent === true ? 'Urgent' : 'Biasa'}`
                        }
                      />
                      <DateField
                        source="date"
                        label="Tanggal Surat"
                        locales="id-ID"
                      />
                      <TextField source="code" label="Kode Surat" />
                      <ReferenceField
                        source="category"
                        link={false}
                        reference="enum_mail_categories"
                        label="Klasifikasi"
                      >
                        <TextField source="description" />
                      </ReferenceField>
                      <ReferenceField
                        source="type"
                        link={false}
                        reference="enum_mail_types"
                        label="Jenis Surat"
                      >
                        <TextField source="description" />
                      </ReferenceField>
                      <TextField source="from" label="Pengirim" />
                      <TextField source="to" label="Kepada" />
                      <TextField source="title" label="Judul Surat" />
                      <TextField source="note" label="Perihal" />
                      {identity?.role === 'SEKRETARIS' && (
                        <EditButton basePath={`/${resource}/${tab}`} />
                      )}
                    </Datagrid>
                  )}
                </List>
              )}
              <Drawer
                open={isEdit}
                onClose={handleClose}
                anchor="bottom"
                variant="temporary"
                SlideProps={{
                  TransitionProps: { mountOnEnter: true, unmountOnExit: true },
                }}
              >
                <Box height="calc(100vh - 128px)" p={1}>
                  {isEdit && (
                    <EditComponent
                      id={match?.params?.id}
                      onCancel={handleClose}
                      resource={`${tab}_mails`}
                      basePath={props.basePath}
                    />
                  )}
                </Box>
              </Drawer>
            </>
          );
        }}
      </Route>
    </>
  );
};

export default ListAllMail;
