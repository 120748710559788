import { CssBaseline } from '@material-ui/core';
import DivisiIcon from 'assets/icons/DivisiIcon';
import JabatanIcon from 'assets/icons/JabatanIcon';
import JenisSuratIcon from 'assets/icons/JenisSuratIcon';
import MasterIcon from 'assets/icons/MasterIcon';
import PenggunaIcon from 'assets/icons/PenggunaIcon';
import axios from 'axios';
import { throttleAdapterEnhancer } from 'axios-extensions';
import { createBrowserHistory as createHistory } from 'history';
import Dashboard from 'layout/dashboard';
import MainLayout from 'layout/layout';
import LoginPage from 'layout/login';
import SettingsPage from 'layout/settings';
import theme from 'layout/theme';
import VerifyPage from 'layout/verify';
import { resourceMessages } from 'messages';
import Quill from 'quill';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import indonesianMessages from 'ra-language-indonesian-new';
import React, { useMemo } from 'react';
import {
  Admin,
  Resource,
  RouteWithoutLayout,
  usePermissions,
} from 'react-admin';
import { pdfjs } from 'react-pdf';
import { Route } from 'react-router';
import EditDivisions from 'resources/divisions/edit';
import EditIncoming from 'resources/incoming/edit';
import ShowIncoming from 'resources/incoming/show';
import EditInternal from 'resources/internal/edit';
import ListInternal from 'resources/internal/list';
import ShowInternal from 'resources/internal/show';
import ListAllMail from 'resources/mails/list';
import EditTemplates from 'resources/master_mail_templates/edit';
import EditOutgoing from 'resources/outgoing/edit';
import ListOutgoing from 'resources/outgoing/list';
import ShowOutgoing from 'resources/outgoing/show';
import EditRoles from 'resources/roles/edit';
import EditUser from 'resources/users/edit';
import SetAuthProvider, { ls } from './providers/auth';
import SetDataProvider from './providers/data';
import CreateDivision from './resources/divisions/create';
import ListDivisions from './resources/divisions/list';
import CreateIncoming from './resources/incoming/create';
import ListIncoming from './resources/incoming/list';
import CreateInternal from './resources/internal/create';
import CreateCategories from './resources/mail_categories/create';
import ListCategories from './resources/mail_categories/list';
import CreateTypes from './resources/mail_types/create';
import ListTypes from './resources/mail_types/list';
import ListGuests from './resources/mail_verification_guests/list';
import CreateTemplates from './resources/master_mail_templates/create';
import ListMasterTemplates from './resources/master_mail_templates/list';
import CreateOutgoing from './resources/outgoing/create';
import CreateRoles from './resources/roles/create';
import ListRoles from './resources/roles/list';
import CreateUser from './resources/users/create';
import ListUser from './resources/users/list';

require('@openfonts/nunito_latin');

[
  'attributors/style/align',
  'attributors/style/background',
  'attributors/style/color',
  'attributors/style/direction',
  'attributors/style/font',
  'attributors/style/size',
].forEach(a => Quill.register(Quill.import(a), true));

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const history = createHistory({
  basename: process.env.REACT_APP_BASE_PATH || '',
});

const messages = {
  id: {
    ...indonesianMessages,
    ...resourceMessages,
  },
};

const i18nProvider = polyglotI18nProvider(locale => messages[locale], 'id', {
  allowMissing: true,
});

axios.interceptors.request.use(config => {
  const session = ls.get('session');

  if (session.token) {
    config.headers.authorization = `Bearer ${session.token}`;
  }

  return config;
});

axios.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response;
  },
  function (error) {
    // Do something with response error
    console.error('AXIOS', error.response);
    return Promise.reject({
      message: error.response?.data?.error || error.message,
      status: error.status,
      axios: error,
    });
  }
);

axios.defaults.adapter = throttleAdapterEnhancer(axios.defaults.adapter, {
  threshold: 1 * 1000,
});
const dataProvider = SetDataProvider(process.env.REACT_APP_API_URL);
const authProvider = SetAuthProvider(process.env.REACT_APP_API_URL);

const ResourceWithPermission = props => {
  const { name, list, create, edit, show, options } = props;
  const resource = options?.resource;
  const resourceToAccess = resource ?? name;
  const { permissions, loading, loaded } = usePermissions();
  const access = useMemo(
    () =>
      !loading && loaded
        ? permissions
          ? permissions[resourceToAccess] || {}
          : {}
        : {},
    [permissions, loading, loaded]
  );
  return (
    <Resource
      {...props}
      name={name}
      list={access.list ? list : undefined}
      create={access.create ? create : undefined}
      edit={access.edit ? edit : undefined}
      show={access.show ? show : undefined}
    />
  );
};
const App = () => {
  return (
    <>
      <CssBaseline />
      <Admin
        theme={theme}
        dashboard={Dashboard}
        layout={MainLayout}
        history={history}
        i18nProvider={i18nProvider}
        dataProvider={dataProvider}
        authProvider={authProvider}
        loginPage={LoginPage}
        customRoutes={[
          <RouteWithoutLayout
            exact
            path="/verify/:classification/:id"
            component={VerifyPage}
          />,
          <Route exact path="/settings" component={SettingsPage} />,
        ]}
        initialState={{ admin: { ui: { sidebarOpen: false } } }}
      >
        <ResourceWithPermission
          name="incoming_mails"
          list={ListIncoming}
          create={CreateIncoming}
          edit={EditIncoming}
          show={ShowIncoming}
          icon={JenisSuratIcon}
          options={{ label: 'Surat Masuk', order: 0 }}
        />
        <ResourceWithPermission
          name="internal_mails"
          list={ListInternal}
          create={CreateInternal}
          edit={EditInternal}
          show={ShowInternal}
          icon={JenisSuratIcon}
          options={{ label: 'Surat Internal', order: 1 }}
        />
        <ResourceWithPermission
          name="outgoing_mails"
          list={ListOutgoing}
          create={CreateOutgoing}
          edit={EditOutgoing}
          show={ShowOutgoing}
          icon={JenisSuratIcon}
          options={{ label: 'Surat Keluar', order: 2 }}
        />
        <ResourceWithPermission
          name="mail_dispositions"
          // list={ListDisposition}
          options={{ label: 'Disposisi' }}
        />
        <ResourceWithPermission
          name="digital_sign_requests"
          // list={ListSign}
          // create={CreateSign}
          // edit={EditSign}
          // show={ShowSign}
          options={{ label: 'Tanda Tangan Digital' }}
        />
        <ResourceWithPermission
          name="users"
          list={ListUser}
          create={CreateUser}
          edit={EditUser}
          icon={PenggunaIcon}
          options={{ label: 'Daftar Pengguna' }}
        />
        <ResourceWithPermission
          name="divisions"
          list={ListDivisions}
          create={CreateDivision}
          edit={EditDivisions}
          icon={DivisiIcon}
          options={{ label: 'Divisi' }}
        />
        <ResourceWithPermission
          name="roles"
          list={ListRoles}
          create={CreateRoles}
          edit={EditRoles}
          icon={JabatanIcon}
          options={{ label: 'Jabatan' }}
        />
        <ResourceWithPermission
          name="enum_mail_types"
          list={ListTypes}
          create={CreateTypes}
          icon={MasterIcon}
          options={{ label: 'Referensi Jenis Surat' }}
        />
        <ResourceWithPermission
          name="enum_mail_categories"
          list={ListCategories}
          create={CreateCategories}
          icon={MasterIcon}
          options={{ label: 'Klasifikasi Surat' }}
        />
        <ResourceWithPermission
          name="master_mail_templates"
          list={ListMasterTemplates}
          create={CreateTemplates}
          edit={EditTemplates}
          icon={MasterIcon}
          options={{ label: 'Master Template Surat' }}
        />
        <ResourceWithPermission
          name="mail_verification_guests"
          list={ListGuests}
          options={{ label: 'Pengunjung Registrasi', order: 4 }}
        />
        <ResourceWithPermission
          name="mails"
          list={ListAllMail}
          icon={JenisSuratIcon}
          options={{ label: 'Riwayat Surat', order: 5 }}
        />
        <Resource name="incoming_mails_stat" />
        <Resource name="system_roles" />
        <Resource name="internal_mails_stat" />
        <Resource name="outgoing_mails_stat" />
        <Resource name="enum_disposition_actions" />
      </Admin>
    </>
  );
};

export default App;
