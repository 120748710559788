import axios from 'axios';
import { flatten } from 'lodash';
import { serialize } from 'object-to-formdata';

const countHeader = 'x-total-data';

const httpClient = (url, config) =>
  axios.request(typeof url === 'object' ? url : { url, ...config });

const SetDataProvider = apiUrl => ({
  getList: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    // console.log(params);

    const query = {
      sort: JSON.stringify({ [field]: order }),
      page,
      limit: perPage,
      filter: JSON.stringify(
        Object.keys(params.filter)
          .filter(k => k !== 'q')
          .reduce((arr, k) => {
            const [operator, key] = k.split('__');

            if (!key) {
              return [...arr, [k, '_ilike', String(params.filter[k]).trim()]];
            } else {
              return [
                ...arr,
                [
                  key,
                  `_${operator}`,
                  Array.isArray(params.filter[k])
                    ? params.filter[k]
                    : String(params.filter[k]).trim(),
                ],
              ];
            }
          }, [])
      ),
      search: params.filter?.q || null,
    };
    const url = `${apiUrl}/${resource}/fn/list`;

    return httpClient(url, { params: query }).then(({ headers, data }) => {
      if (!headers[countHeader]) {
        throw new Error(
          `The ${countHeader} header is missing in the HTTP Response. The simple REST data provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare ${countHeader} in the Access-Control-Expose-Headers header?`
        );
      }

      return {
        data,
        total: parseInt(headers[countHeader.toLowerCase()]),
      };
    });
  },
  getOne: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/fn/detail/${params.id}`).then(
      ({ data }) => ({
        data,
      })
    ),
  getMany: (resource, params) => {
    console.log(resource, params.ids, flatten(params.ids));
    const query = {
      filter: JSON.stringify([['id', '_in', params.ids]]),
    };
    const url = `${apiUrl}/${resource}/fn/list`;
    return httpClient(url, { params: query }).then(({ data }) => ({
      data,
    }));
  },
  getManyReference: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;

    const query = {
      sort: JSON.stringify({ [field]: order }),
      page,
      limit: perPage,
      filter: JSON.stringify(
        Object.keys(params.filter)
          .filter(k => k !== 'q')
          .reduce((arr, k) => {
            const [operator, key] = k.split('__');

            if (!key) {
              return [...arr, [k, '_ilike', String(params.filter[k]).trim()]];
            } else {
              return [
                ...arr,
                [
                  key,
                  `_${operator}`,
                  Array.isArray(params.filter[k])
                    ? params.filter[k]
                    : String(params.filter[k]).trim(),
                ],
              ];
            }
          }, [])
      ),
      search: params.filter?.q || null,
    };

    const url = `${apiUrl}/${resource}/fn/list`;

    return httpClient(url, { params: query }).then(({ headers, data }) => {
      if (!headers[countHeader]) {
        throw new Error(
          `The ${countHeader} header is missing in the HTTP Response. The simple REST data provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare ${countHeader} in the Access-Control-Expose-Headers header?`
        );
      }
      return {
        data,
        total: parseInt(headers[countHeader.toLowerCase()]),
      };
    });
  },
  update: (resource, params) => {
    const data = serialize(
      Object.keys(params.data).reduce(
        (obj, k) => ({
          ...obj,
          [k]:
            typeof params.data[k] === 'string'
              ? params.data[k].trim()
              : params.data[k],
        }),
        {}
      ),
      {
        booleansAsIntegers: false,
      }
    );

    return httpClient(`${apiUrl}/${resource}/fn/edit/${params.id}`, {
      headers: {
        'X-Http-Method-Override': 'PUT',
      },
      method: 'POST',
      data,
    }).then(({ data }) => ({ data }));
  },
  updateMany: (resource, params) => {
    const data = serialize(
      Object.keys(params.data).reduce(
        (obj, k) => ({
          ...obj,
          [k]:
            typeof params.data[k] === 'string'
              ? params.data[k].trim()
              : params.data[k],
        }),
        {}
      ),
      {
        booleansAsIntegers: false,
      }
    );

    return Promise.all(
      params.ids.map(id =>
        httpClient(`${apiUrl}/${resource}/fn/edit/${id}`, {
          headers: {
            'X-Http-Method-Override': 'PUT',
          },
          method: 'POST',
          data,
        })
      )
    ).then(responses => ({ data: responses.map(({ data }) => data.id) }));
  },
  create: (resource, params) => {
    const data = serialize(
      Object.keys(params.data).reduce(
        (obj, k) => ({
          ...obj,
          [k]:
            typeof params.data[k] === 'string'
              ? params.data[k].trim()
              : params.data[k],
        }),
        {}
      ),
      {
        booleansAsIntegers: true,
      }
    );

    return httpClient(`${apiUrl}/${resource}/fn/create`, {
      method: 'POST',
      data,
    }).then(({ data }) => ({
      data: { ...params.data, id: data.id },
    }));
  },
  delete: (resource, params) => {
    if (resource === 'users') {
      return httpClient(`${apiUrl}/${resource}/fn/archive/${params.id}`, {
        method: 'PATCH',
      }).then(({ data }) => ({ data }));
    } else {
      return httpClient(`${apiUrl}/${resource}/fn/remove/${params.id}`, {
        method: 'DELETE',
      }).then(({ data }) => ({ data }));
    }
  },
  deleteMany: (resource, params) =>
    Promise.all(
      params.ids.map(id =>
        httpClient(`${apiUrl}/${resource}/fn/remove/${id}`, {
          method: 'DELETE',
        })
      )
    ).then(responses => ({
      data: responses.map(({ data }) => data.id),
    })),
});

export default SetDataProvider;
