import { createTheme, responsiveFontSizes } from '@material-ui/core';

export const getScheme = () =>
  typeof window !== 'undefined' && window.localStorage.getItem('prefers-theme')
    ? window.localStorage.getItem('prefers-theme')
    : window.matchMedia &&
      window.matchMedia('(prefers-color-scheme: dark)').matches
    ? 'dark'
    : 'light';

const baseTheme = responsiveFontSizes(
  createTheme({
    palette: {
      type: getScheme(),
      primary: {
        main: getScheme() === 'dark' ? '#6fa9eb' : '#15549A',
        light: '#A1BBD7',
      },
      secondary: { main: '#09AB8F' },
      error: { main: '#F39C12' },
      background: {
        default: getScheme() === 'dark' ? '#3B3E40' : '#FEFEFE',
        paper: getScheme() === 'dark' ? '#3F4040' : '#FBFFFF',
      },
      divider: '#DFE5EB',
    },
    shape: {
      borderRadius: 8,
    },
    typography: {
      fontFamily: [
        'Nunito',
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
    },
  })
);

const theme = {
  ...baseTheme,
  shadows: [...Array(25).keys()].map(i =>
    i === 0
      ? 'none'
      : `0 2px ${2.5 + i / 1.75}px 1px rgba(0, 0, 0, 0.14), 0 4px ${
          2.5 + i / 1.75
        }px -7px ${baseTheme.palette.background.default}`
  ),
  palette: {
    ...baseTheme.palette,
    primary: {
      ...baseTheme.palette.primary,
      gradient: `linear-gradient(70deg, ${baseTheme.palette.primary.dark} 0%, ${baseTheme.palette.primary.light} 100%)`,
    },
    secondary: {
      ...baseTheme.palette.secondary,
      gradient: `linear-gradient(70deg, ${baseTheme.palette.secondary.dark} 0%, ${baseTheme.palette.secondary.light} 100%)`,
    },
  },
  overrides: {
    MuiAppBar: {
      colorDefault: {
        backgroundColor: baseTheme.palette.background.default,
      },
      colorSecondary: {
        background: `linear-gradient(60deg, ${baseTheme.palette.secondary.main} 50%, ${baseTheme.palette.secondary.dark} 90%)`,
      },
      colorPrimary: {
        background: `linear-gradient(60deg, ${baseTheme.palette.primary.main} 50%, ${baseTheme.palette.primary.dark} 90%)`,
      },
    },
    MuiBottomNavigation: {
      root: {
        backgroundColor: baseTheme.palette.background.default,
      },
    },
    MuiButton: {
      label: {
        textTransform: 'initial',
      },
      containedPrimary: {
        boxShadow: `0 4px 10px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px ${baseTheme.palette.primary.main} !important`,
        // background: `linear-gradient(60deg, ${baseTheme.palette.primary.main} 50%, ${baseTheme.palette.primary.dark}) 90%`,
        // color: baseTheme.palette.getContrastText(
        //   baseTheme.palette.primary.main
        // ),
        '&$disabled': {
          boxShadow: 'none !important',
        },
      },
      containedSecondary: {
        boxShadow: `0 4px 10px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px ${baseTheme.palette.secondary.main} !important`,
        // background: `linear-gradient(60deg, ${baseTheme.palette.secondary.main} 50%, ${baseTheme.palette.secondary.dark}) 90%`,
        // color: baseTheme.palette.getContrastText(
        //   baseTheme.palette.secondary.main
        // ),
        '&$disabled': {
          boxShadow: 'none !important',
        },
      },
    },
    MuiBottomNavigationAction: {
      root: {
        // minWidth: 72,
        // color: 'transparent',
        // '&$selected': {
        //   color: 'transparent',
        //   paddingTop: 16,
        // },
      },
    },
    MuiCssBaseline: {
      '@global': {
        body: {},
      },
    },
    RaMenuItemLink: {
      active: {
        // borderLeft: '3px solid #4f3cc9',
        color: baseTheme.palette.primary.main,
        borderLeft: `${baseTheme.spacing(1)}px solid ${
          baseTheme.palette.primary[getScheme() === 'dark' ? 'light' : 'dark']
        }`,
        '& .MuiListItemIcon-root': {
          color:
            baseTheme.palette.primary[
              getScheme() === 'dark' ? 'light' : 'dark'
            ],
        },
      },
      icon: {
        color: baseTheme.palette.text.secondary,
      },
      root: {
        color: baseTheme.palette.text.secondary,
        borderLeft: `${baseTheme.spacing(1)}px solid transparent`,
        borderTop: `${baseTheme.spacing(0.5)}px solid transparent`,
        borderBottom: `${baseTheme.spacing(0.5)}px solid transparent`,
      },
    },
  },
};

export default theme;
