import {
  Avatar,
  Box,
  Chip,
  Grid,
  styled,
  Tooltip,
  Typography,
  useTheme,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import DisposisiIcon from 'assets/icons/DisposisiIcon';
import JenisKlasifikasiIcon from 'assets/icons/JenisKlasifikasiIcon';
import JenisSuratIcon from 'assets/icons/JenisSuratIcon';
import SendtoIcon from 'assets/icons/SendtoIcon';
import moment from 'moment';
import React, { useMemo } from 'react';
import { ReferenceField, TextField, useGetList } from 'react-admin';
import Carousel from 'react-material-ui-carousel';

const PrimaryLightChip = styled(Chip)(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  color: theme.palette.getContrastText(theme.palette.primary.light),
}));

export const DispositionInfo = ({ record }) => {
  const theme = useTheme();

  return (
    <Box
      color={`primary.${theme.palette.type === 'dark' ? 'light' : 'dark'}`}
      minHeight={128}
    >
      <Typography
        variant="subtitle1"
        color="inherit"
        gutterBottom={record.action !== 'ARSIP'}
      >
        {record.action === 'ARSIP'
          ? record.created_by_division_name
          : record.name}
      </Typography>
      {record.has_responded === false ? (
        <Typography variant="subtitle2" color="error" gutterBottom>
          Belum Diproses, Dikirim oleh{' '}
          {
            <ReferenceField
              record={record}
              source="created_by_division"
              link={false}
              reference="divisions"
            >
              <TextField source="name" />
            </ReferenceField>
          }
        </Typography>
      ) : (
        <Typography variant="subtitle2" color="inherit" gutterBottom>
          {record.action}
        </Typography>
      )}
      <Typography variant="subtitle2" color="inherit">
        Pada {moment(record.created_at).format('DD MMM YYYY HH:mm')}
      </Typography>
      <Box clone fontStyle={'italic'}>
        <Typography variant="body2" color="textSecondary">
          {record.action_note}
        </Typography>
      </Box>
    </Box>
  );
};

export const DispositionsCarousel = ({ record, resource }) => {
  const { loading, error, ids, data } = useGetList(
    'mail_dispositions',
    {},
    {},
    {
      eq__classification: resource.replace('_mails', '').toUpperCase(),
      eq__mail_id: record.id,
      eq__has_pending: true,
    }
  );

  const dispositions = useMemo(
    () =>
      !loading && !error
        ? ids.reduce((arr, id) => {
            const items = [];

            if (data[id]) {
              if (data[id].action === 'ARSIP') {
                items.push(data[id]);
              }

              data[id].recipient_divisions
                .filter(d => d.has_responded === false)
                .forEach(d => {
                  items.push({ ...data[id], ...d });
                });

              data[id].recipient_roles
                .filter(d => d.has_responded === false)
                .forEach(d => {
                  items.push({ ...data[id], ...d });
                });

              data[id].recipient_users
                .filter(d => d.has_responded === false)
                .forEach(d => {
                  items.push({ ...data[id], ...d });
                });
            }

            return [...arr, ...items];
          }, [])
        : [],
    [loading, error, ids, data]
  );

  return !loading && !error ? (
    <Carousel navButtonsAlwaysInvisible indicators={ids.length > 1}>
      {dispositions.map((d, idx) => (
        <DispositionInfo record={d} key={idx} />
      ))}
    </Carousel>
  ) : (
    <Skeleton height={96} width="100%" />
  );
};

export const StatusChips = ({ record, resource }) => {
  const { data, loading, loaded, ids, error } = useGetList(
    'mail_dispositions',
    {},
    {},
    {
      eq__classification: resource.replace('_mails', '').toUpperCase(),
      eq__mail_id: record.id,
    }
  );

  const status = useMemo(
    () =>
      !loading && !error && data
        ? ids.reduce((obj, id) => {
            if (!data[id]) {
              return obj;
            }

            if (data[id].action === 'ARSIP') {
              return {
                ...obj,
                [data[id].action]: [
                  ...(obj[data[id].action] || []),
                  data[id].creator,
                ],
              };
            } else {
              return {
                ...obj,
                [data[id].action]: [
                  ...(obj[data[id].action] || []),
                  ...data[id].recipient_divisions
                    .filter(r => !r.has_responded)
                    .map(r => r.name),
                  ...data[id].recipient_roles
                    .filter(r => !r.has_responded)
                    .map(r => r.name),
                  ...data[id].recipient_users
                    .filter(r => !r.has_responded)
                    .map(r => r.name),
                ],
              };
            }
          }, {})
        : {},
    [loading, data, ids, error]
  );

  return Object.keys(status).map(s => (
    <Grid item key={s}>
      <Tooltip title={status[s].join(', ')}>
        <Chip
          label={s}
          size="small"
          avatar={<Avatar>{status[s].length}</Avatar>}
        />
      </Tooltip>
    </Grid>
  ));
};

export const MailChips = ({ record, resource }) => (
  <Grid container spacing={1}>
    {record.urgent && (
      <Grid item>
        <Chip label="PENTING" size="small" />
      </Grid>
    )}
    <Grid item>
      <PrimaryLightChip
        label={moment(record.date).format('DD MMM YYYY')}
        size="small"
      />
    </Grid>
    <StatusChips record={record} resource={resource} />
  </Grid>
);

export const MailInfo = ({ record }) => (
  <Box my={1}>
    <Box clone fontStyle="italic">
      <Typography color="textSecondary" variant="body2">
        {record.code}
      </Typography>
    </Box>
    <Typography variant="h6" component="div">
      {record.title}
    </Typography>
    <Grid container spacing={4}>
      <Grid item>
        <Typography variant="body2" color="textSecondary">
          Pengirim
        </Typography>
        <Typography variant="body1">{record.from || '-'}</Typography>
        <Typography variant="subtitle2">
          {moment(record.created_at).format('DD MMM YYYY')}
        </Typography>
      </Grid>
      <Grid item>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          alignContent="center"
          height="100%"
        >
          {/* <DoubleArrowIcon fontSize="large" /> */}
          <SendtoIcon color="primary" fontSize="large" />
        </Box>
      </Grid>
      <Grid item>
        <Typography variant="body2" color="textSecondary">
          Ditujukan Kepada
        </Typography>
        <Typography variant="body1">{record.to || '-'}</Typography>
      </Grid>
    </Grid>
  </Box>
);

export const MailDisposition = ({ record, resource }) => {
  const theme = useTheme();

  const handlePropagation = event => {
    event.stopPropagation();
    event.preventDefault();
  };

  return (
    <Box width={theme.spacing(60)}>
      <Grid container spacing={2}>
        <Grid item xs={7} onClick={handlePropagation}>
          <Grid container spacing={1}>
            <Grid item>
              <DisposisiIcon color="primary" fontSize="small" />
            </Grid>
            <Grid item xs={10}>
              <Typography variant="body2" color="textSecondary">
                Disposisi Terakhir
              </Typography>
              {/* <ReferenceField
            record={record}
            source="current_disposition_id"
            link={false}
            reference="mail_dispositions">
            <DispositionInfo />
          </ReferenceField> */}
              {/* <ReferenceArrayField
            record={record}
            source="last_disposition_ids"
            link={false}
            reference="mail_dispositions"> */}
              <DispositionsCarousel record={record} resource={resource} />
              {/* </ReferenceArrayField> */}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={5}>
          <Box marginBottom={5}>
            <Grid container spacing={1}>
              <Grid item>
                <JenisKlasifikasiIcon color="primary" fontSize="small" />
              </Grid>
              <Grid item xs={10}>
                <Typography variant="body2" color="textSecondary">
                  Klasifikasi
                </Typography>
                <ReferenceField
                  record={record}
                  source="category"
                  link={false}
                  reference="enum_mail_categories"
                >
                  <TextField source="description" />
                </ReferenceField>
              </Grid>
            </Grid>
          </Box>
          <Grid container spacing={1}>
            <Grid item>
              <JenisSuratIcon color="primary" fontSize="small" />
            </Grid>
            <Grid item xs={10}>
              <Typography variant="body2" color="textSecondary">
                Jenis Surat
              </Typography>
              <ReferenceField
                record={record}
                source="type"
                link={false}
                reference="enum_mail_types"
              >
                <TextField source="description" />
              </ReferenceField>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
