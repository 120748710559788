import { Container } from '@material-ui/core';
import { Create, TextInput } from 'react-admin';

import { CompactForm, RaGrid } from 'ra-compact-ui';

const CreateCategories = props => {
  return (
    <Container maxWidth="md" disableGutters>
      <Create {...props}>
        <CompactForm layoutComponents={[RaGrid]}>
          <TextInput
            fullWidth
            label="Deskripsi Klasifikasi Surat"
            source="description"
          />
        </CompactForm>
      </Create>
    </Container>
  );
};

export default CreateCategories;
