import { useMediaQuery } from '@material-ui/core';
import {
  Datagrid,
  DateField,
  Filter,
  List,
  SimpleList,
  TextField,
  TextInput,
  UrlField,
  useRecordContext,
} from 'react-admin';
import { MailField } from 'shared/MailField';

const LocationField = ({ source, ...props }) => {
  const record = useRecordContext(props);

  if (record.location) {
    record.position_url = `https://www.google.com/maps/search/?api=1&query=${
      record.location.replace('(', '').replace(')', '').split(',')[1]
    },${record.location.replace('(', '').replace(')', '').split(',')[0]}`;
  }

  return record.location ? (
    <UrlField record={record} source="position_url" target="_blank" />
  ) : null;
};

const GridFilters = props => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
  </Filter>
);

const ListGuests = props => {
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

  return (
    <List {...props} filters={<GridFilters />} exporter={false}>
      {isSmall ? (
        <SimpleList primaryText={record => record.name} linkType={false} />
      ) : (
        <Datagrid>
          <DateField
            source="timestamp"
            label="Tanggal"
            showTime
            locales="id-ID"
            options={{
              dateStyle: 'full',
              timeStyle: 'short',
              // hour: 'numeric',
            }}
          />
          <TextField source="name" label="Nama" />
          <TextField source="from" label="Instansi" />
          <TextField source="ip_address" label="Alamat IP" />
          <LocationField source="location" label="Koordinat" />
          <MailField source="code" label="Kode Surat" />
        </Datagrid>
      )}
    </List>
  );
};

export default ListGuests;
