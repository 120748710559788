import { SvgIcon } from '@material-ui/core';
const NightmodeIcon = props => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.0001 15.7671C21.6224 16.3902 20.093 16.737 18.4827 16.737C12.4215 16.737 7.50793 11.8234 7.50793 5.76223C7.50793 4.1519 7.85476 2.62257 8.47779 1.24487C4.66991 2.96689 2.02039 6.79892 2.02039 11.2498C2.02039 17.311 6.93395 22.2245 12.9951 22.2245C17.446 22.2245 21.278 19.575 23.0001 15.7671Z"
    />
    <path d="M11.5245 4.46353C11.6741 4.00287 12.3259 4.00287 12.4755 4.46353L12.5613 4.72746C12.6282 4.93347 12.8202 5.07295 13.0368 5.07295H13.3143C13.7987 5.07295 14.0001 5.69276 13.6082 5.97746L13.3837 6.14058C13.2085 6.2679 13.1351 6.49358 13.2021 6.69959L13.2878 6.96352C13.4375 7.42418 12.9103 7.80724 12.5184 7.52254L12.2939 7.35942C12.1186 7.2321 11.8814 7.2321 11.7061 7.35942L11.4816 7.52254C11.0897 7.80724 10.5625 7.42418 10.7122 6.96353L10.7979 6.69959C10.8649 6.49358 10.7915 6.2679 10.6163 6.14058L10.3918 5.97746C9.99992 5.69276 10.2013 5.07295 10.6857 5.07295H10.9632C11.1798 5.07295 11.3718 4.93347 11.4387 4.72746L11.5245 4.46353Z" />
    <path d="M18.5245 10.4635C18.6741 10.0029 19.3259 10.0029 19.4755 10.4635L19.5613 10.7275C19.6282 10.9335 19.8202 11.0729 20.0368 11.0729H20.3143C20.7987 11.0729 21.0001 11.6928 20.6082 11.9775L20.3837 12.1406C20.2085 12.2679 20.1351 12.4936 20.2021 12.6996L20.2878 12.9635C20.4375 13.4242 19.9103 13.8072 19.5184 13.5225L19.2939 13.3594C19.1186 13.2321 18.8814 13.2321 18.7061 13.3594L18.4816 13.5225C18.0897 13.8072 17.5625 13.4242 17.7122 12.9635L17.7979 12.6996C17.8649 12.4936 17.7915 12.2679 17.6163 12.1406L17.3918 11.9775C16.9999 11.6928 17.2013 11.0729 17.6857 11.0729H17.9632C18.1798 11.0729 18.3718 10.9335 18.4387 10.7275L18.5245 10.4635Z" />
    <path d="M18.5245 3.46353C18.6741 3.00287 19.3259 3.00287 19.4755 3.46353L19.5613 3.72746C19.6282 3.93347 19.8202 4.07295 20.0368 4.07295H20.3143C20.7987 4.07295 21.0001 4.69276 20.6082 4.97746L20.3837 5.14058C20.2085 5.2679 20.1351 5.49358 20.2021 5.69959L20.2878 5.96352C20.4375 6.42418 19.9103 6.80724 19.5184 6.52254L19.2939 6.35942C19.1186 6.2321 18.8814 6.2321 18.7061 6.35942L18.4816 6.52254C18.0897 6.80724 17.5625 6.42418 17.7122 5.96353L17.7979 5.69959C17.8649 5.49358 17.7915 5.2679 17.6163 5.14058L17.3918 4.97746C16.9999 4.69276 17.2013 4.07295 17.6857 4.07295H17.9632C18.1798 4.07295 18.3718 3.93347 18.4387 3.72746L18.5245 3.46353Z" />
  </SvgIcon>
);
export default NightmodeIcon;
